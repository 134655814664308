import React from 'react'
import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import useStyles from "../../assets/styles/HomeStyles";
import logo from '../../assets/images/PoweredByDQ_Default.png'
import ProductLogo from '../../assets/images/PoweredByDqProducts.png'
 const Home= ()=>{
     const classes = useStyles();
     const cardData = [
         {
             image: logo,
             title: 'ProgramCreator',
             description: 'Program creation tool for program providers such as ed-techs, education leaders, and teachers to create digital literacy programs, instantly.',
             points: ['Program validation report for organizations to check their digital literacy program’s rating.',
                 'Program validation report for organizations to check their digital literacy program’s rating.',
                 'Proprietary DQGPT-system to create any digital literacy learning content and format and program in seconds.'],
         },
         {
             image: logo,
             title: 'ProgramRating',
             description: 'Program validation report for organizations to check their digital literacy program’s rating.',
             points: ['Proprietary DQGPT-system to create any digital literacy learning content and format and program in seconds.',
                 'Matches your program to potential business opportunities such as grants and calls via market analysis',
                 'Matches your program to potential business opportunities such as grants and calls via market analysis'],
         },
         {
             image: logo,
             title: 'DQGuide',
             description: 'Program validation report for organizations to check their digital literacy program’s rating.',
             points: ['Matches your program to potential business opportunities such as grants and calls via market analysis',
                 'Matches your program to potential business opportunities such as grants and calls via market analysis',],
         },
     ];
    return(
        <>
            <div className={classes.pageContainer}>
                <img src={ProductLogo} alt="image" className={classes.topImage}/>

                <div className={classes.cardsContainer}>
                    {cardData.map((card, index) => (
                        <Card key={index} className={classes.card}>
                            <CardActionArea>
                                <img src={card.image} alt={card.title} className={classes.cardImage}/>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h4" gutterBottom style={{color:'#0397a6'}}>
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body1" paragraph style={{padding:'10px', textAlign:'left'}}>
                                        {card.description}
                                    </Typography>
                                    <ul>
                                        {card.points.map((point, idx) => (
                                            <li key={idx} className={classes.listItemText}>
                                               {point}
                                            </li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    )
 }

export default Home;