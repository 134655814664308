import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    NotFound as NotFoundPage,
    MockProductPage
} from './pages';

import {
    ProgramCreationPage as ProgramCreation,
    ProgramDashboardPage as ProgramDashboard,
    ProgramOutlinePage as ProgramOutline,
    HomePage as Home,
    ProgramsListPage as ProgramsList,
    ProgramEditPage as ProgramEdit,
    ProgramPreviewPage as ProgramPreview,
    DynamicLearningPage as DynamicLearning,
    ProgramResourcesPage as ProgramResources,
    ProgramUpgradePage as ProgramUpgrade,
    ProgramRatingPage as ProgramRating,
    RatingContentReportPage as RatingContentReport,
    TopRatedProgramsPage as TopRatedPrograms,
    DQGuidePage as DQGuide,
} from './pages';

import SelectPackagesPage from "./pages/Packages/SelectPackages.page";
import ProgramCreationn from "./pages/Packages/ProgramCreation.page";
import ProgramSelection from "./pages/Packages/ProgramSelection.page";
import LearningContent from "./pages/Packages/CustomDigitalProgram/LearningContent.comp";
import SuggestedCourse from "./pages/Packages/CustomDigitalProgram/SuggestedCourse.page";
import ProgramName from "./pages/Packages/ProgramName.page";
import AccessProgram from "./pages/Packages/AccessProgram.page";
import SuggestedCourseCopy from "./pages/Packages/CustomDigitalProgram/SuggestedCourseCopy.page";
// import ProgramDashboard from "./views/ver_24.10/ProgramDashboard/ProgramDashboard.view";

const preAuthRoutes = [
    { path: '/login', component: LoginPage },
    { path: '/register', component: RegisterPage },
    { path: '/account/recover', component: InitPasswordResetPage },
    { path: '/auth/reset/password', component: PasswordResetPage, no_redirect: true }
];

const privateRoutes = [
    { path: '/auth/confirm/email', component: ConfirmEmailPage, layout: MinimalLayout },
    { path: '/password/change', component: ChangePasswordPage },
    { path: '/logout', component: LogoutPage },
    { path: '/select_packages', component: SelectPackagesPage },
    { path: '/program_creationn', component: ProgramCreationn },
    { path: '/program_selection', component: ProgramSelection },
    { path: '/learning_content', component: LearningContent },
    { path: '/suggested_course', component: SuggestedCourse },
    { path: '/suggested_course_copy', component: SuggestedCourseCopy },
    { path: '/program_name', component: ProgramName },
    { path: '/program_access', component: AccessProgram },

    { path: '/mock_pd', component: MockProductPage },

    // {path: '/program_creation', component: ProgramCreation},
    // {path: '/program_details', component: ProgramDetails},
    // {path: '/program_overview', component: ProgramOutline},
    {path: '/home', component: Home },

    // Program Creator Routes
    {path: '/program/new', component: ProgramCreation},   // Page to create a new program
    {path: '/programs/list', component: ProgramsList}, // List of programs

    {path: '/program/:programId/dashboard', component: ProgramDashboard},  // Dashboard for a specific program
    {path: '/program/:programId/outline', component: ProgramOutline},      // Outline view for a specific program
    {path: '/program/:programId/edit', component: ProgramEdit},            // Edit page for a specific program
    {path: '/program/:programId/preview', component: ProgramPreview},      // Preview page for a specific program
    {path: '/program/:programId/dynamic_learning', component: DynamicLearning},  // Dynamic learning component for the program
    {path: '/program/:programId/resources', component: ProgramResources},  // Resources view for the program
    {path: '/program/:programId/upgrade', component: ProgramUpgrade},  // Upgrade view for the program
    //
    // // Program Rating Routes
    {path: '/rating/top', component: TopRatedPrograms},  // Top-rated programs page
    {path: '/rating/:programId', component: ProgramRating},  // Rating details for a specific program
    {path: '/rating/:programId/content_report', component: RatingContentReport},  // Content report for a specific program's rating
    //
    // // DQ Guide Routes
    {path: '/dq_guide', component: DQGuide},  // DQ Guide page
];

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/select_packages" />
            {preAuthRoutes.map((route, index) => (
                <PreAuthRouteWithLayout
                    key={index}
                    exact
                    layout={MinimalLayout}
                    {...route}
                />
            ))}
            {privateRoutes.map((route, index) => (
                <PrivateRouteWithLayout
                    key={index}
                    exact
                    layout={route.layout || MainLayout}
                    {...route}
                />
            ))}
            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />
        </Switch>
    );
};

export default Routes;