import React from 'react';
/**
 * View: ProductDisplay
 *
 * **Data Schema:**
 *
 * data = {
 *   productId: string,      // Unique identifier for the product
 *   productName: string,    // Name of the product
 *   price: number,          // Product price
 *   available: boolean,     // Availability status
 *   discountCode: string,   // Discount code applicable
 *   cartItems: Array,       // List of items in the cart [{ id, name, quantity }]
 *   userDetails: {          // User information for checkout
 *     userId: string,
 *     name: string,
 *     email: string
 *   }
 * }
 *
 * **Actions:**
 * - `addToCart(productId)`: Adds the product to the cart.
 * - `processCheckout(cartItems, userDetails)`: Processes the checkout with cart items and user information.
 * - `applyDiscount(code)`: Applies a discount code to the current order.
 * - `updateUserProfile(userId, profileData)`: Updates user profile with new data.
 * - `logInteraction(type, details)`: Logs specific user interactions for analytics.
 *
 * **Options:**
 *
 * options = {
 *   visualState: string,   // Determines visual style, e.g., 'compact' or 'expanded'
 *   showHeader: boolean,   // Toggles visibility of the header
 *   showFooter: boolean    // Toggles visibility of the footer
 * }
 *
 * **Usage Example:**
 * <ProductDisplay data={data} actions={actions} options={options} />
 */


const ProductDisplay = ({ data, actions, options }) => {
    const { productId, productName, price, available, discountCode, cartItems, userDetails } = data;
    const { visualState, showHeader, showFooter } = options;

    return (
        <div
            style={{margin: '33px'}}
            className={`product-display ${visualState}`}>
            {showHeader && <header><h1>{productName}</h1></header>}

            <main>
                <p><strong>Price:</strong> ${price.toFixed(2)}</p>
                <p><strong>Availability:</strong> {available ? 'In Stock' : 'Out of Stock'}</p>

                <button onClick={() => actions.addToCart(productId)}>
                    Add to Cart
                </button>

                {discountCode && (
                    <button onClick={() => actions.applyDiscount(discountCode)}>
                        Apply Discount
                    </button>
                )}

                <button onClick={() => actions.processCheckout(cartItems, userDetails)}>
                    Proceed to Checkout
                </button>
            </main>

            {showFooter && (
                <footer>
                    <p>Customer: {userDetails.name} ({userDetails.email})</p>
                </footer>
            )}
        </div>
    );
};

export default ProductDisplay;