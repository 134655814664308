import React, { useState, useEffect } from 'react';
import ProgramsListView from '../../views/ver_24.10/ProgramsList.view';

const ProgramsList = () => {
    const [programs, setPrograms] = useState([]);

    const data = {
        programs,
    };

    const actions = {
        fetchProgramsAction: () => {
            // Simulate an API call to fetch programs
            return new Promise((resolve) => {
                setTimeout(() => {
                    const fetchedPrograms = [
                        {
                            id: 'program1',
                            title: 'Digital Literacy Program',
                            description: 'A program about digital citizenship and online safety.',
                            submissionDate: '2023-10-17',
                            package: 'Freemium',
                            status: 'Completed',
                        },
                        {
                            id: 'program2',
                            title: 'Cybersecurity Basics',
                            description: 'An introduction to cybersecurity for teenagers.',
                            submissionDate: '2023-10-15',
                            package: 'Premium',
                            status: 'In Progress',
                        },
                    ];
                    resolve(fetchedPrograms);
                }, 1000);
            });
        },

        createProgramAction: () => {
            window.location.href = '/program/new';
        },

        viewProgramAction: (programId) => {
            window.location.href = `/program/${programId}/dashboard?step=2`;
        },
    };

    const options = {
    };

    useEffect(() => {
        actions.fetchProgramsAction().then((fetchedPrograms) => {
            setPrograms(fetchedPrograms);
        });
    }, []);

    return (
        <>
            <div>
                <ProgramsListView data={data} actions={actions} options={options}/>
            </div>
        </>
    );
}

export default ProgramsList;