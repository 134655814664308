import React, {useState} from 'react'
import {Box, Card, CardContent, Divider, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useLocation} from "react-router-dom";
import Loader from "../../../ui_library/ver_24.10/Loader/Loader.comp";
import useStyles from "../../../assets/styles/ProgramPreviewStyles";
import ProgramIntro from "./ProgramIntro.comp";

const ProgramEdit = ({data, options, actions}) => {
    const location = useLocation();
    const { selectedSkills, curriculumCode } = location.state || { selectedSkills: [], curriculumCode: null };
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedSection, setSelectedSection] = useState('Student Outcomes');
    const [openUnits, setOpenUnits] = useState(null);
    const [openLessons, setOpenLessons] = useState({});

    const handleToggleUnit = (unitIndex) => {
        setOpenUnits(openUnits === unitIndex ? null : unitIndex);
        setOpenLessons({});
    };

    const handleToggleLesson = (lessonId) => {
        setOpenLessons((prev) => ({
            ...prev,
            [lessonId]: !prev[lessonId],
        }));
    };

    const handleViewLesson = (lesson) => {
        setSelectedLesson(lesson);
        setSelectedSection('Introduction');
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', minHeight:'90vh' }}>
                <Box className={classes.leftContainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        data.curriculum.map((unit, unitIndex) => (
                            <Card
                                className={classes.card}
                                key={unit.unitId}
                                style={{
                                    backgroundColor: openUnits === unitIndex ? '#fff' : '#F17632',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <CardContent className={classes.cardContent}>
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        style={{
                                            fontSize: '1.3rem',
                                            color: openUnits === unitIndex ? '#F17632' : '#fff',
                                            cursor: 'pointer',
                                            padding: '8px !important',
                                            transition: 'all 0.3s ease',
                                        }}
                                        onClick={() => handleToggleUnit(unitIndex)}
                                    >
                                        {unit.title}
                                    </Typography>

                                    {openUnits === unitIndex && (
                                        <>
                                            <Divider style={{ height: '4px', marginTop: '13px', backgroundColor: '#F17632', borderRadius: '15px' }} />
                                            {unit.lessons.map((lesson) => (
                                                <Box
                                                    className={classes.lessonContainer}
                                                    key={lesson.lessonId}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        paddingBottom: '8px',
                                                    }}
                                                >
                                                    <Box className={classes.sectionRow}>
                                                        <Typography
                                                            variant="h4"
                                                            className={classes.sectionText}
                                                            style={{ cursor: 'pointer', color: '#F17632' }}
                                                            onClick={() => handleViewLesson(lesson)}
                                                        >
                                                            {lesson.title}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.viewButton}
                                                            onClick={() => handleToggleLesson(lesson.lessonId)}
                                                            style={{
                                                                fontSize: '0.9rem',
                                                                padding: '3px 14px',
                                                                marginLeft: '10px',
                                                                backgroundColor: '#F17632',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </Box>
                                                    {openLessons[lesson.lessonId] && (
                                                        <Box className={classes.dropdownOptions}>
                                                            <Box className={classes.lessonRow} style={{ marginTop: '0px !important' }}>
                                                                <Typography className={classes.sectionText}>Introduction</Typography>
                                                                <Button
                                                                    variant="contained"
                                                                    className={classes.viewButton}
                                                                    onClick={() => handleViewLesson(lesson)}
                                                                >
                                                                    View
                                                                </Button>
                                                            </Box>
                                                            <Box className={classes.lessonRow} style={{ marginTop: '0px !important' }}>
                                                                <Typography className={classes.sectionText}>Assessment</Typography>
                                                                <Button
                                                                    variant="contained"
                                                                    className={classes.viewButton}
                                                                >
                                                                    View
                                                                </Button>
                                                            </Box>
                                                            <Box className={classes.lessonRow} style={{ marginTop: '0px !important' }}>
                                                                <Typography className={classes.sectionText}>Learning Content</Typography>
                                                                <Button
                                                                    variant="contained"
                                                                    className={classes.viewButton}
                                                                    onClick={() => handleViewLesson(lesson)}
                                                                >
                                                                    View
                                                                </Button>
                                                            </Box>
                                                            <Box className={classes.lessonRow} style={{ marginTop: '0px !important' }}>
                                                                <Typography className={classes.sectionText}>Application Activity</Typography>
                                                                <Button
                                                                    variant="contained"
                                                                    className={classes.viewButton}
                                                                    onClick={() => handleViewLesson(lesson)}
                                                                >
                                                                    View
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        ))
                    )}
                </Box>

                <Box className={classes.rightContainer}>
                    {selectedLesson && (
                        <>
                            {selectedSection === 'Introduction' && (
                                <ProgramIntro
                                    key={selectedLesson.title}
                                    selectedLesson={selectedLesson}
                                    selectedSkills={selectedSkills}
                                />
                            )}
                        </>
                    )}
                </Box>
            </div>
        </>
    );
};

export default ProgramEdit;