import React, {useEffect, useState} from 'react';
import { Box, Card, CardContent, Divider, Typography,Slider, Button } from '@material-ui/core';
import useStyles from "../../assets/styles/ProgramPreviewStyles";
import Loader from "../../ui_library/ver_24.10/Loader/Loader.comp";
import { useHistory } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import {IconButton} from "@mui/material";

const ProgramPreview = ({data, options, actions}) => {
    const classes = useStyles();
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (data.slideData && data.slideData.length > 0) {
            setIsContentLoaded(true);
        } else {
            setIsContentLoaded(false);
        }
    }, [data.slideData]);

    const progressValue = (currentSlide / (data.slideData.length - 1)) * 100;

    const handleToggleDropdown = (unitIndex) => {
        setOpenDropdowns((prev) => (prev === unitIndex ? null : unitIndex));
    };

    const handleViewLesson = (lesson) => {
        setSelectedLesson(lesson);
        setSelectedSection('Lesson Details');
        setCurrentSlide(0);
    };

    const handleNextSlide = () => {
        setCurrentSlide((prev) => prev + 1);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => prev - 1);
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }

    function exitProgramHandler() {
        console.log("exit program");
        history.push('/program/programId123/dashboard?step=2');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', minHeight:'90vh' }}>
            <Box className={classes.leftContainer}>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    data.curriculum.map((unit, unitIndex) => (
                        <Card
                            className={classes.card}
                            key={unit.unitId}
                            style={{
                                backgroundColor: openDropdowns === unitIndex ? '#fff' : '#F17632',
                                transition: 'background-color 0.3s ease',
                            }}
                        >
                            <CardContent className={classes.cardContent}>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    style={{
                                        fontSize: '1.3rem',
                                        color: openDropdowns === unitIndex ? '#F17632' : '#fff',
                                        cursor: 'pointer',
                                        padding: '8px !important',
                                        transition: 'all 0.3s ease',
                                    }}
                                    onClick={() => handleToggleDropdown(unitIndex)}
                                >
                                    {unit.title}
                                </Typography>

                                {openDropdowns === unitIndex && (
                                    <>
                                        <Divider style={{ height: '4px', marginTop: '13px', backgroundColor:'#F17632', borderRadius:'15px' }} />
                                        {unit.lessons.map((lesson) => (
                                            <Box
                                                className={classes.lessonContainer}
                                                key={lesson.lessonId}
                                                style={{
                                                    backgroundColor: '#fff',
                                                    paddingBottom: '8px',
                                                }}
                                            >
                                                <Box className={classes.sectionRow}>
                                                    <Typography
                                                        variant="h4"
                                                        className={classes.sectionText}
                                                        style={{ cursor: 'pointer', color: '#F17632' }}
                                                        onClick={() => handleViewLesson(lesson)}
                                                    >
                                                        {lesson.title}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.viewButton}
                                                        onClick={() => handleViewLesson(lesson)}
                                                        style={{
                                                            fontSize: '0.9rem',
                                                            padding: '3px 14px',
                                                            marginLeft: '10px',
                                                            backgroundColor: '#F17632',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    ))
                )}
            </Box>

            {/* Right Container - Slideshow */}
            <Box className={classes.rightContainer}>
                {selectedLesson && selectedSection && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            paddingTop: '50px',
                        }}
                    >
                        <Slider
                            style={{paddingBottom:'30px'}}
                            value={currentSlide}
                            min={0}
                            max={data.slideData.length - 1}
                            step={1}
                            classes={{
                                root: classes.slider,
                                track: classes.track,
                                thumb: classes.thumb,
                            }}
                            disabled
                        />
                        <Typography variant="h5" className={classes.lessonTitle}>
                            {selectedLesson.title}
                        </Typography>
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                padding: '20px',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                textAlign: 'center',
                            }}
                        >
                            {data.slideData[currentSlide] && (() => {
                                const SlideComponent = data.slideData[currentSlide].component;
                                const slideOptions = data.slideData[currentSlide].options;
                                return  <SlideComponent
                                    data={data}
                                    options={{ ...options, ...slideOptions }}
                                    actions={actions}
                                    {...data.slideData[currentSlide].props}
                                    backgroundColor={slideOptions?.backgroundColor || '#000'}
                                    height={slideOptions?.height}
                                    showButton={slideOptions?.showButton}
                                />
                            })()}

                            {/* Exit Button */}

                        </Box>
                    </Box>
                )}

                {currentSlide > 0 && (
                    <IconButton
                        onClick={handlePrevSlide}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '10px',
                            backgroundColor: '#ef6528',
                            color: '#fff',
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                )}

                {isContentLoaded && data.slideData.length > 1 && currentSlide < data.slideData.length - 1 && (
                    <IconButton
                        onClick={handleNextSlide}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            backgroundColor: '#ef6528',
                            color: '#fff',
                        }}
                    >
                        <ArrowForward />
                    </IconButton>
                )}

                <Button
                    onClick={exitProgramHandler}
                    style={{
                        marginBottom:'20px',
                        backgroundColor: '#ef6528',
                        color: '#fff',
                    }}
                >
                    Exit Program
                </Button>
            </Box>
        </div>
    );
};

export default ProgramPreview;
