import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Card, Button } from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import PopupEditor from "../../../ui_library/ver_24.10/PopupEditor/PopupEditor.comp";
import Loader from "../../../ui_library/ver_24.10/Loader/Loader.comp";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        padding:'0px',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'flex-start',
        transition: 'all 0.3s ease',
    },
    rightContainer: {
        flex: 2,
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        transition: 'all 0.3s ease',
        opacity: '1',
    },
    card: {
        width: '450px',
        margin: theme.spacing(2),
        textAlign: 'left',
        background:' linear-gradient(to bottom, #F17430 5%, #FECE60 50%)',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '0 !important',
    },
    lesson: {
        color:'#002060',
        margin: theme.spacing(1, 0),
        padding: '0 20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: 'white',
        width: '90%',
        margin:'10px 0px',
        height: '4px !important',
        marginLeft: '20px !important',
        marginRight: '20px !important',
        borderRadius: '2px',
    },
    viewButton: {
        color: 'white',
        backgroundColor: '#4294A5',
        '&:hover': {
            backgroundColor: '#06215B',
            color: '#fff',
        },
    },
    lessonTitle: {
        marginBottom: theme.spacing(2),
        backgroundColor: '#F17632',
        borderRadius:'10px',
        color: '#fff',
        width: '100%',
        padding: '4px',
        textAlign: 'center',
    },
    objectiveCard: {
        border: '2px dotted #0097a7',
        width:'95%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px',
        boxShadow: 'none',
    },
    objectiveContent: {
        textAlign: 'left',
        marginRight: '25px',
        '& li': {
            display: 'inline-block', // Ensure bullet points and text are on the same line
            marginBottom: theme.spacing(1),
        },
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    button: {
        color: '#fff',
    },
    Button:{
        border:'2px solid #4294A5',
        color:'#4294A5',
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
    },
}));


const LearningContent = ({selectedLesson }) => {
    const classes = useStyles();
    const [openPopup, setOpenPopup] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const selectedObjectives = [];
    // const handleEdit = (content) => {
    //     setPopupContent(content);
    //     setOpenPopup(true);
    // };
    const handleEdit = (contentItem) => {
        // Create a slide content array from contentItem.body
        const slides = contentItem.body.map((section, index) => ({
            title: `Slide ${index + 1}`,
            content: section.content,
        }));

        setPopupContent(slides);
        setOpenPopup(true);
    };

    const handleSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            history.push({
                pathname: '/program_name',
            });
        }, 2000);
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }

    return (
        <Container className={classes.mainContainer}>
            <Box className={classes.rightContainer}>
                    <Box style={{width:'100%',}}>
                        <Typography variant="h5" className={classes.lessonTitle}>
                            {selectedLesson.verbose_name}
                        </Typography>
                        <Card
                            className={classes.objectiveCard}
                            style={{
                                border: 'none',
                                position: 'relative',
                                marginBottom: '20px',
                                margin:'10px',
                                paddingTop: '40px',
                                borderRadius: '10px',
                            }}
                        >
                            <Box className={classes.objectiveContent}>
                                <Typography variant="h6" style={{ color: '#06215B', fontSize:'17px' }}>Learning Content</Typography>
                                {selectedLesson.definition}
                            </Box>
                        </Card>

                        <Card
                            className={classes.objectiveCard}
                            key={'01'}
                            style={{
                                border:'2px dotted #0097a7',
                                position: 'relative',
                                marginBottom: '20px',
                                margin:'10px',
                                paddingTop: '40px',
                                borderRadius: '10px',
                                display:'flex',
                                flexDirection:'column'
                            }}
                        >
                            <Box className={classes.tag}>
                                {`Slide ${1}`}
                            </Box>

                            <Typography variant="h5" style={{ color: '#06215B', marginBottom: '10px' }}>
                                {'Summary'}
                            </Typography>

                            <Box className={classes.objectiveContent}>
                                {selectedLesson.learning_goals.map((goal, index) => (
                                    <li><Typography variant="body1" style={{ color: '#06215B' }}>{goal.description}</Typography></li>
                                ))}

                            </Box>

                        </Card>


                        {selectedLesson.learning_content.length > 0 && (
                            <Card
                                className={classes.objectiveCard}
                                style={{
                                    border:'2px dotted #0097a7',
                                    position: 'relative',
                                    marginBottom: '20px',
                                    margin:'10px',
                                    paddingTop: '40px',
                                    borderRadius: '10px',
                                    display:'flex',
                                    flexDirection:'column'
                                }}
                            >
                                <Box className={classes.tag}>
                                    {`Slide ${selectedLesson.learning_goals.length + 0}`}
                                </Box>
                                <Typography variant="h5" style={{ color: '#06215B', marginBottom: '10px' }}>
                                    {selectedLesson.learning_content[0].main_heading}
                                </Typography>

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:'100%'}}>
                                    <div style={{display: 'flex', flexDirection:'column', alignItems: 'start', justifyContent: 'flex-start'}}>
                                        {/*{contentItem.body.map((section, sectionIndex) => (*/}
                                        {selectedLesson.learning_content[0].body.map((slide, slideIndex) => (
                                            <Box key={slideIndex} style={{marginBottom: '15px'}}>
                                                <Typography variant="h6"
                                                            style={{color: '#06215B', marginBottom: '5px'}}>
                                                    {slide.title}
                                                </Typography>
                                                <Typography variant="body2" style={{color: '#000'}}>
                                                    {slide.content}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </div>
                                        <Box className={classes.buttonGroup} style={{marginTop: '10px'}}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleEdit(selectedLesson.learning_content[0])}
                                                style={{
                                                    border: '2px solid #ef6528',
                                                    backgroundColor: '#fff',
                                                    color: '#ef6528',
                                                    padding: '5px 23px',
                                                    borderRadius: '14px'
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                style={{
                                                    border: '2px solid #ef6528',
                                                    backgroundColor: '#fff',
                                                    color: '#ef6528',
                                                    padding: '5px 23px',
                                                    borderRadius: '14px'
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </div>
                            </Card>
                            )}
                    </Box>
                <Button
                    variant="outlined"
                    className={classes.Button}
                    style={{ padding: '7px 37px' }}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>

            {/*<PopupEditor*/}
            {/*    open={openPopup}*/}
            {/*    content={{*/}
            {/*        title: selectedObjectives[2]?.title || '',*/}
            {/*        content: selectedObjectives[2]?.content || '',*/}
            {/*    }}*/}
            {/*    onClose={() => setOpenPopup(false)}*/}
            {/*/>*/}
            <PopupEditor
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                content={popupContent}
            />
        </Container>
    );
};

export default LearningContent;
