import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import ChatBotView from "../../ui_library/ver_24.10/ChatBot/ChatBot.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    title: {
        color: '#595959',
        fontWeight: 'bold',
        marginTop: '50px',
        fontSize: '35px',
    },
    subtitle: {
        color: '#EE6628',
        fontSize: '26px',
    },
}));

const ProgramCreation =({data, options, actions}) =>{
    const classes = useStyles();

    return(
        <>
            <div className={classes.container}>

                <div style={{display: 'block'}}>
                    <h1 className={classes.title}>
                        What {data.programTitle} Do You Want to Build?
                    </h1>
                    {data.chats.length === 0 && (
                        <p className={classes.subtitle} style={{marginTop: '50px'}}>
                            ie. "Create a 45-minute program about cyber security for teenagers.”<br/> or <br/>“Make a
                            2-hour program about AI literacy for 3rd graders in Singapore.”
                        </p>
                    )}

                </div>
                <div style={{width: '79%', left: '13%', bottom: '10%', position: 'absolute'}}>
                    <ChatBotView
                        data={data}
                        actions={actions}
                        options={options}
                    />
                </div>
            </div>
        </>
    )
}

export default ProgramCreation;