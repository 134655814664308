import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import Logo from '../../../assets/images/PoweredByDQ_Default.png';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
    },
    loaderSvg: {
        width: '300px',
        height: '300px',
        position: 'absolute',
    },
    logoImage: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '220px',
    },
    '@global': {
        '@keyframes dash-anim': {
            '0%': {
                strokeDasharray: '0 0 calc(2 * var(--1deg)) calc(358 * var(--1deg))',
            },
            '50%': {
                strokeDasharray: '0 calc(35 * var(--1deg)) calc(290 * var(--1deg)) calc(35 * var(--1deg))',
            },
            '100%': {
                strokeDasharray: '0 calc(358 * var(--1deg)) calc(2 * var(--1deg))',
            },
        },
        '@keyframes full-rotation-anim': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
    },
    circle: {
        '--r': '47px',
        '--1deg': 'calc(2 * pi * var(--r) / 360)',
        strokeWidth: 'calc((50% - var(--r)) * 2)',
        stroke: 'url(#rainbow)',
        animation: 'dash-anim 1400ms ease-in-out infinite, full-rotation-anim 2000ms linear infinite',
        transformOrigin: '50% 50%',
    },
}));

const Loader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.loaderContainer}>
            <svg className={classes.loaderSvg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="rainbow" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#F7185A" />
                        <stop offset="16.66%" stopColor="#FB4D2B" />
                        <stop offset="33.33%" stopColor="#FFB72A" />
                        <stop offset="50%" stopColor="#88D035" />
                        <stop offset="66.66%" stopColor="#0DA694" />
                        <stop offset="83.33%" stopColor="#0C76C7" />
                        <stop offset="100%" stopColor="#612892" />
                    </linearGradient>
                </defs>
                <circle
                    cx="50"
                    cy="50"
                    r="47"
                    stroke="url(#rainbow)"
                    fill="none"
                    className={classes.circle}
                />
            </svg>
            <img src={Logo} alt="Logo" className={classes.logoImage} />
        </Box>
    );
};

export default Loader;
