import React from 'react'

const ProgramUpgrade = () => {
    return (
        <>
            <div>
                <h1>Program Upgrade</h1>
                <p>Program Upgrade page for a specific program.</p>
            </div>
        </>
    );
}

export default ProgramUpgrade;