import React, {useEffect, useState} from 'react';
import { Card, CardContent, Button, Typography } from '@mui/material';
import useStyles from '../../../assets/styles/ProgramDashboardStyles';
import {useHistory, useLocation, useParams} from 'react-router-dom';

/**
 * View: ProgramDashboard
 *
 * **Data Schema:**
 *
 * data = {
 *   steps: Array,                  // List of steps in the program (ProgramSteps component)
 *   steps[] = {
 *     id: number,                  // Unique identifier for each step
 *     icon: string,                 // Icon image for the step
 *     title: string,                // Title of the step
 *     content: string,              // Description or content for the step
 *     buttons: Array,               // List of buttons for the step (ProgramSteps component)
 *     buttons[] = {
 *       label: string,              // Button label
 *       url: string,                // URL for navigation (if applicable)
 *       onClick: function,           // Function to execute on button click (optional)
 *     },
 *     isDisabled: boolean           // Indicates whether the step is disabled
 *   }
 * }
 *
 * **Actions:**
 * - actions = {
 *  }
 *
 * **Options:**
 * options = {
 *  }
 */

const ProgramDashboard = ({ data, options, actions }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { programId } = useParams();
    const [activeStep, setActiveStep] = useState(() => {
        const queryParams = new URLSearchParams(location.search);
        const savedStepFromURL = queryParams.get('step');
        return savedStepFromURL === '1' ? 0 : savedStepFromURL ? parseInt(savedStepFromURL, 10) - 1 : 0;
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const savedStepFromURL = queryParams.get('step');
        if (savedStepFromURL) {
            setActiveStep(parseInt(savedStepFromURL, 10) - 1);
        }
    }, [location.search]);

    const handleNavigation = (url, stepIndex, stepCompletion) => {
        history.push({
            pathname: url.replace(':programId', programId),
            search: `?step=${stepIndex + 1}`,
        });
    };

    const handleUpgradeToPro = () => {
        history.push('/program/:programId/upgrade');
    };

    return (
        <>
            <Typography variant="h4" className={classes.title}>Here’s your program!</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '0px 70px' }}>
                {data.map((step, index) => (
                    <Card
                        key={index}
                        className={`${classes.card} ${index <= activeStep ? classes.enabledCard : classes.disabledCard}`}
                        style={{ pointerEvents: index <= activeStep ? 'auto' : 'none' }}
                    >
                        <CardContent style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            {(index === 2 || index === 3) && (
                                <Button
                                    className={classes.upgradeButton}
                                    onClick={handleUpgradeToPro}
                                >
                                    Upgrade to Pro
                                </Button>
                            )}
                            <img src={step.icon} alt={step.title}
                                 style={{width: '70px', height: '70px', marginBottom: '20px', marginTop: '20px'}}/>
                            <Typography variant="h5"
                                        style={{color: '#0397a6', marginBottom: '10px'}}>{step.title}</Typography>
                            <Typography variant="body1" style={{
                                marginTop: '7px',
                                padding: '0 10px',
                                textAlign: 'center'
                            }}>{step.content}</Typography>
                            <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '25px'}}>
                                {step.buttons.map((button, btnIndex) => (
                                    <Button
                                        key={btnIndex}
                                        className={`${classes.button}`}
                                        variant="contained"
                                        onClick={() => {
                                            if (button.onClick) {
                                                button.onClick();
                                            } else if (button.url) {
                                                handleNavigation(button.url, index, button.stepCompletion);
                                            }
                                        }}
                                        disabled={index > activeStep}  // Disable if index is greater than activeStep
                                    >
                                        {button.label}
                                    </Button>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    );
};

export default ProgramDashboard;
