import React, { useState, useEffect } from 'react';
import { Button, Card, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import RemoveIcon from "@material-ui/icons/Remove";
import useStyles from "../../../assets/styles/OverviewStyles";
import ChatBotView from "../../../ui_library/ver_24.10/ChatBot/ChatBot.comp";
/**
 * View: ProgramOutline
 *
 * **Data Schema:**
 *
 * data = {
 *   units: Array,               // List of units within the program
 *     [
 *       {
 *         unitId: string,           // Unique identifier for the unit
 *         title: string,        // Unit title
 *         lessons: Array,       // List of lessons within the unit
 *           [
 *             {
 *               lessonId: string,     // Unique identifier for the lesson
 *               title: string,  // Lesson title
 *               studentOutcomes: Array // List of outcomes for the lesson
 *                 [
 *                   string,    // Description of the student outcome
 *                 ]
 *             }
 *           ]
 *       }
 *     ]
 * }
 *
 * **Actions:**
 * - `removeUnitAction(unitToRemoveIndex)`: Removes the unit from the data array based on the unitId.
 *
 * **Options:**
 *
 * options = {
 *   expandedUnits: string,    // Controls whether units are 'expanded' or 'collapsed'
 *   openDialog: boolean,      // Controls whether the remove dialog is open
 *   unitToRemove: null,       // controls whether the
 * }
 *
 * **Usage Example:**
 * <ProgramOutline data={data} actions={actions} options={options} />
 */

const ProgramOutline = ({ data, actions, options }) =>{
    const classes = useStyles();
    const { unitsData } = data;
    const { removeUnitAction } = actions;
    const [expandedLessons, setExpandedLessons] = useState({});
    const [expandedUnits, setExpandedUnits] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [unitToRemove, setUnitToRemove] = useState(null);

    useEffect(() => {
        if (unitsData && unitsData.length > 0) {
            const allUnitIndices = unitsData.map((unit, index) => index);
            setExpandedUnits(allUnitIndices);

            setExpandedLessons({
                0: { 0: true },
            });
        }
    }, [unitsData]);

    const handleRemoveUnit = (unitId) => {
        setUnitToRemove(unitId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setUnitToRemove(null);
    };

    const handleConfirmRemove = () => {
        if (unitToRemove) {
            removeUnitAction(unitToRemove);
            handleCloseDialog();
        }
    };

    const handleLessonToggle = (unitIndex, lessonIndex) => {
        setExpandedLessons((prev) => ({
            ...prev,
            [unitIndex]: {
                ...prev[unitIndex],
                [lessonIndex]: !prev[unitIndex]?.[lessonIndex],
            },
        }));
    };

    return(
        <>
            <div style={{position: 'relative', height: '80vh', }}>
                <div className={classes.container} style={{height: data.chats.length > 0 ? '72vh' : '72vh'}}>
                    <Typography variant="h5" className={classes.title}>
                        What Digital Literacy Program Do You Want to Build?
                    </Typography>

                    <div className={classes.cardsContainer}>
                        <Card className={classes.card}>
                            <Typography variant="h6">Audience</Typography>
                            <Typography variant="subtitle1">Grades 3-4 (Ages 8-10)</Typography>
                        </Card>
                        <Card className={classes.card}>
                            <Typography variant="h6">Program Structure</Typography>
                            <Typography variant="subtitle1">4 Units / 19 Lessons</Typography>
                        </Card>
                        <Card className={classes.card}>
                            <Typography variant="h6">Program Length</Typography>
                            <Typography variant="subtitle1">30 min</Typography>
                        </Card>
                    </div>

                    <Typography variant="body1" className={classes.overview}>
                        <b>Overview:</b> Students will learn about digital citizenship and how to be safe and
                        responsible online.
                    </Typography>

                    <div className={classes.unitContainer}>
                        {unitsData.map((unit, index) => (
                            <div key={index} className={classes.unit}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Button
                                        onClick={() => setExpandedUnits(prev => prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index])}
                                        className={classes.button}
                                        style={{
                                            color: '#EE6628',
                                            fontWeight: 'bold',
                                            outline: 'none',
                                            boxShadow: 'none',
                                            fontSize: '18px',
                                            justifyContent: 'space-between',
                                            textAlign: 'left',
                                        }}
                                        fullWidth
                                    >
                                        {unit.title}
                                        {expandedUnits.includes(index) ? <ArrowDropUp/> : <ArrowDropDown/>}
                                    </Button>

                                    <IconButton
                                        onClick={() => handleRemoveUnit(unit.unitId)}
                                        style={{color: '#EE6628', marginLeft: '10px'}}
                                    >
                                        <RemoveIcon/>
                                    </IconButton>
                                </div>

                                <Collapse in={expandedUnits.includes(index)} timeout="auto" unmountOnExit>
                                    <List>
                                        {unit.lessons.map((lesson, lessonIndex) => (
                                            <div key={lessonIndex}>
                                                <ListItem button onClick={() => handleLessonToggle(index, lessonIndex)}>
                                                    <Typography variant="h6" className={classes.lesson} style={{ marginLeft: '8px' }}>
                                                        {lesson.title}
                                                    </Typography>
                                                    {expandedLessons[index]?.[lessonIndex] ? <ArrowDropUp   style={{color: '#EE6628',}}/> : <ArrowDropDown   style={{color: '#EE6628',}}/>}
                                                </ListItem>
                                                <Collapse in={expandedLessons[index]?.[lessonIndex]} timeout="auto" unmountOnExit>
                                                    {lesson.studentOutcomes && (
                                                        <List>
                                                            <ListItem style={{ paddingTop: '0px' }}>
                                                                <Typography variant="h6" style={{ color: '#0397a6', marginLeft: '20px' }}>
                                                                    Student Outcomes:
                                                                </Typography>
                                                            </ListItem>
                                                            <ul style={{ marginLeft: '40px', listStyleType: 'disc' }}>
                                                                {lesson.studentOutcomes.map((outcome, outcomeIndex) => (
                                                                    <li key={outcomeIndex} style={{ marginBottom: '10px', color: '#606060' }}>
                                                                        {outcome}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </List>
                                                    )}
                                                </Collapse>
                                            </div>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ))}
                    </div>
                    <ChatBotView
                        data={data}
                        actions={actions}
                        options={options}
                    />
                </div>
            </div>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this unit?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                    <Button onClick={handleConfirmRemove} color="primary">Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ProgramOutline
