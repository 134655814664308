import React, {useState} from 'react'
import ProgramDashboardView from "../../views/ver_24.10/ProgramDashboard/ProgramDashboard.view";
import Icon1 from '../../assets/images/Icon4.png';
import Icon2 from '../../assets/images/Icon3.png';
import Icon3 from '../../assets/images/Icon6.png';
import Icon4 from '../../assets/images/Icon1.png';

const ProgramDashboardPage= () =>{
    const cardsData = [
        {
            icon: Icon1,
            title: "Program Overview",
            content: "Overview of your curated program",
            buttons: [
                { label: 'View/Edit', url: '/program/:programId/outline',stepCompletion: true, }
            ],
            stepIndex: 0,
        },
        {
            icon: Icon2,
            title: "Your Program",
            content: "Preview your ready-to-go online program or edit it manually",
            buttons: [
                { label: 'Preview', url: '/program/:programId/preview', stepCompletion: false, },
                { label: 'Edit', url: '/program/:programId/edit', stepCompletion: true, },
            ],
            stepIndex: 1,
        },
        {
            icon: Icon3,
            title: "Your Program With",
            content: "Your created program will automatically adjust its content and lessons based on the learner's current skill level or progress. The material is personalized in real-time to better suit each individual's needs.",
            buttons: [
                { label: 'Enable', onClick: () => console.log('Enable step 3'),stepCompletion: false, },
                { label: 'Preview', onClick: () => console.log('Preview step 3'), stepCompletion: false, },
            ],
            stepIndex: 2,
        },
        {
            icon: Icon4,
            title: "Resources",
            content: "Everything you need to run your program online, offline, or integrate with your system via API",
            buttons: [
                { label: 'Access', onClick: () => console.log('Access step 4'), stepCompletion: false, },
            ],
            stepIndex: 3,
        }
    ];

    const options = {
    };

    const actions = {
    };

    return(
        <>
            <div>
                <ProgramDashboardView data={cardsData} options={options} actions={actions}/>
            </div>
        </>
    );
}

export default ProgramDashboardPage;