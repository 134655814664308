import React, { useState } from 'react';
import {ProductDisplayView} from '../../views';

const ProductPage = () => {
    // Sample data for the product and user details
    const [cartItems, setCartItems] = useState([]);
    const [userDetails] = useState({ userId: '123', name: 'John Doe', email: 'john.doe@example.com' });

    const data = {
        productId: 'p001',
        productName: 'Sample Product',
        price: 99.99,
        available: true,
        discountCode: 'WELCOME10',
        cartItems,
        userDetails
    };

    // Actions handling business logic
    const actions = {
        addToCart: (productId) => {
            const newCartItem = { id: productId, name: data.productName, quantity: 1 };
            setCartItems([...cartItems, newCartItem]);
            console.log(`Product ${productId} added to cart.`);
        },

        processCheckout: (cartItems, userDetails) => {
            // Business logic to process the checkout
            console.log('Processing checkout...', { cartItems, userDetails });
        },

        applyDiscount: (code) => {
            // Apply discount logic, e.g., verifying the code and calculating the new price
            console.log(`Applying discount code: ${code}`);
        },

        updateUserProfile: (userId, profileData) => {
            // Simulate updating user profile
            console.log(`Updating profile for user ${userId}`, profileData);
        },

        logInteraction: (interactionType, details) => {
            // Log interaction for analytics purposes
            console.log(`Interaction logged: ${interactionType}`, details);
        }
    };

    // Configuration options for visual appearance
    const options = {
        visualState: 'expanded', // Could also be 'compact'
        showHeader: true,
        showFooter: false
    };

    // Rendering the ProductDisplay view component
    return (
        <div>
            <ProductDisplayView
                data={data}
                actions={actions}
                options={options}
            />
        </div>
    );
};

export default ProductPage;