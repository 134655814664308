import React from 'react'

const ProgramRating = () => {
    return (
        <>
            <div>
                <h1>Program Rating</h1>
                <p>Program Rating page for a specific program.</p>
            </div>
        </>
    );
}

export default ProgramRating;