import React, {useEffect, useState} from 'react'
import {Box, Card, CardContent, Container, Divider, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Loader from "../../../ui_library/ver_24.10/Loader/Loader.comp";
import ProgramPreAssessment from "./ProgramPreAssessment.comp";
import LearningContent from "./LearningContent.comp";
import ProgramPostAssessment from "./ProgramPostAssessment.comp";
import ApplicationActivity from "./ApplicationActivity.comp";
import Introduction from "./Introduction.comp";
import {
    getProgramState,
    selectCurriculum,
    selectDigitalSkills,
    updateProgramState
} from "../../../services/pbdq_v2.service";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingTop:'30px',
        display: 'flex',
        flexDirection: 'column',
        margin:'0px',
        transition: 'all 0.3s ease',
        justifyContent: 'start',
        alignItems: 'center',
        maxWidth: '100%',
        minHeight: '100vh',
    },
    mainTitle: {
        color: '#1b3867',
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        fontWeight: 'bold',
    },
    leftContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '12px',
        alignItems: 'center',
        flexDirection: 'column',
        transition: 'all 0.3s ease',
        minHeight: '70vh',
        width: '50%',
    },
    sectionRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        position: 'relative',
    },
    sectionText: {
        fontSize: '21px',
        fontWeight:'700',
        color:'#fff',
    },
    rightContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop:'28px',

    },
    cardsContainer:{
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
        border: '2px solid #f3f1ed',
        borderRadius:'35px',
        marginTop: '23px',
        transition: 'all 0.3s ease',
        minHeight: '70vh',
        width: '100%',
        boxShadow:'0 6px 13px rgba(0, 0, 0, 0.2)',
    },
    card: {
        width: '485px',
        margin: theme.spacing(2),
        textAlign: 'left',
        background:' linear-gradient(to bottom, #FFA65A 30%, #FF763D 70%)',
        borderRadius:'30px',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        position:'relative',
    },
    lesson: {
        color:'#002060',
        margin: theme.spacing(1, 0),
        padding: '0 20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: 'white',
        width: '100%',
        margin:'10px 0px',
        height: '4px !important',
        marginRight: '20px !important',
        borderRadius: '2px',
    },
    viewButton: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        backgroundColor: '#fff',
        color: '#fff',
        fontSize:'15px',
        height: '20px',
        width: '100px',
        opacity:1,
        transition: 'height 0.3s ease, opacity 0.3s ease, color 0.3s ease',
        boxShadow: 'none',
        borderRadius: '7px 7px 0px 0px',
        '&:hover': {
            backgroundColor: '#fff',
            boxShadow: 'none',
            height: '40px',
            color: '#000',
            opacity: 1,
        },
    },
    selectedViewButton: {
        backgroundColor: '#06215B',
        '&:hover': {
            backgroundColor: '#06215B',
        },
    },
    lessonTitle: {
        marginBottom: theme.spacing(2),
        backgroundColor: '#F17632',
        borderRadius:'10px',
        color: '#fff',
        width: '100%',
        padding: '4px',
        textAlign: 'center',
    },
    objectiveCard: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px',
        boxShadow: 'none',
    },
    objectiveContent: {
        textAlign: 'left',
        marginRight: '25px',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    button: {
        color: '#fff',
    },
    Button:{
        border:'2px solid #4294A5',
        color:'#4294A5',
    },
    assignment: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        width:'430px',
        marginBottom:'20px',
    },
    lessonContainer: {
        marginBottom: theme.spacing(2),
    },
    dropdownContainer: {
        backgroundColor: '#f3f1ed',
        padding: theme.spacing(2),
        // borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        zIndex: 1000,
        maxHeight: '300px',
        overflowY: 'auto',
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
    },
    Video:{
        width: '75%',
        height: '200px',
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        marginTop:'20px',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '24px',
        cursor: 'pointer',
    }
}));

const SuggestedCourseCopy= () =>{
    const location = useLocation();
    const { selectedSkills, curriculumCode } = location.state || { selectedSkills: [], curriculumCode: null };
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedSection, setSelectedSection] = useState('Student Outcomes');
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedCurriculum, setSelectedCurriculum] = useState([]);
    const skillTitle = selectedSkills.length > 0 ? selectedSkills[0] : 'Digital Citizen';
    const [assignmentData, setAssignmentData] = useState([]);
    useEffect(() => {
        const fetchCurriculumData = async () => {
            try {
                if (curriculumCode) {
                    setLoading(true);
                    const result = await selectCurriculum(curriculumCode);
                    if (result.data && result.data.programState) {
                        const { curriculum ,user_program_units} = result.data.programState;
                        setSelectedCurriculum(user_program_units);
                    }
                }
            } catch (error) {
                console.error('Error selecting curriculum:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCurriculumData();
    }, [curriculumCode]);

    useEffect(() => {
        const prepareCurriculumFromDQCs = async () => {
            try {
                if (selectedSkills.length > 0) {
                    setLoading(true);
                    console.log('nav:log -----> selectedSkills -> ', selectedSkills);
                    // TODO:NAV:LOG skills data is not complete, just passing the title here, needed dqc_no array to prepare custom curriculum.
                    const result = await selectDigitalSkills(curriculumCode);
                    if (result.data && result.data.programState) {
                        const { curriculum ,user_program_units} = result.data.programState;
                        setSelectedCurriculum(user_program_units);
                    }
                }
            } catch (error) {
                console.error('Error preparing curriculum:', error);
            } finally {
                setLoading(false);
            }
        };
        prepareCurriculumFromDQCs();
    }, [selectedSkills]);


    console.log(selectedCurriculum, "&&&&&&&&&&&&&&&&&&&");

    const handleViewContent = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedAssignment(null);
        setSelectedSection(section);
    };
    // const handleRemoveOutcome = (index) => {
    //     const updatedOutcomes = selectedLesson.studentOutcomes.filter((_, i) => i !== index);
    //     setSelectedLesson(prevLesson => ({ ...prevLesson, studentOutcomes: updatedOutcomes }));
    // };
    const handleRemoveOutcome = async (index) => {
        try {
            const result = await getProgramState();
            const { programState } = result.data;

            if (!selectedLesson || !selectedLesson.student_outcomes) {
                console.error('Selected lesson or student outcomes are undefined');
                return;
            }
            const updatedOutcomes = selectedLesson.student_outcomes.filter((_, i) => i !== index);

            if (updatedOutcomes.length === selectedLesson.student_outcomes.length) {
                console.error('Failed to remove outcome, index not found');
                return;
            }
            const updatedProgramUnits = programState.user_program_units.map(unit => {
                if (unit.gsm_no === selectedLesson.gsm_no) {
                    return {
                        ...unit,
                        student_outcomes: updatedOutcomes
                    };
                }
                return unit;
            });
            const response = await updateProgramState(updatedProgramUnits);
            if (response.data.success) {
                console.log('Outcome removed and saved to local store.');
                setSelectedLesson(prevLesson => ({
                    ...prevLesson,
                    student_outcomes: updatedOutcomes
                }));
            } else {
                console.error('Failed to update program state');
            }
            console.log('Updated program units:', updatedProgramUnits);

        } catch (error) {
            console.error('Error removing student outcome:', error);
        }
    };

    const handleSave = async () => {
        try {
            const result = await getProgramState();
            const { programState } = result.data;

            const updatedProgramUnits = programState.user_program_units.map(unit => {
                if (unit.gsm_no === selectedLesson.gsm_no) {
                    return { ...unit, student_outcomes: selectedLesson.student_outcomes };
                }
                return unit;
            });

            const response = await updateProgramState(updatedProgramUnits);
            if (response.data.success) {
                console.log('Outcomes saved successfully');
            } else {
                console.error('Failed to save outcomes');
            }
        } catch (error) {
            console.error('Error saving outcomes:', error);
        }
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }
    const toggleDropdown = (lessonIndex, cardIndex) => {
        setOpenDropdowns(prevState => ({
            ...prevState,
            [`${lessonIndex}-${cardIndex}`]: !prevState[`${lessonIndex}-${cardIndex}`]
        }));
    };
    const handleViewOutcomes = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };
    const handleViewLesson = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };
    const handleViewActivity = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };

    const handleViewPreAssignment = (preAssessmentData, lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setAssignmentData(preAssessmentData);
        setSelectedAssignment(assignmentData);
    };

    const handleViewPostAssignment = (postAssessmentData, lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(assignmentData);
        setAssignmentData(postAssessmentData);
    };
    const generateProgram = () => {
        setLoading(true);
        setTimeout(() => {
            history.push({
                pathname: '/program_name',
            });
        }, 2000);
    };
    return(
        <Container className={classes.mainContainer}>
            <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                <Box className={classes.leftContainer}>
                    {['DQ 01', 'DQ 02', 'DQ 03'].map((dq, dqIndex) => (
                        <Card className={classes.card} key={`dq-${dqIndex}`}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h3" component="h2" style={{ fontSize:'42px', fontWeight:'bolder', marginTop: '20px', color: '#fff' }}>
                                    {dq}
                                </Typography>
                                <Divider className={classes.divider} style={{ height: '4px !important' }} />
                                {[1, 2, 3].map((lessonNumber) => (
                                    <Box className={classes.lessonContainer} key={`lesson-${lessonNumber}`}>
                                        <Box
                                            className={classes.sectionRow}
                                            style={{ borderBottom: '2px solid #fff', paddingBottom: '8px', position: 'relative' }}
                                        >
                                            <Typography className={classes.sectionText} style={{ cursor: 'pointer' }}>
                                                Lesson {lessonNumber}
                                            </Typography>
                                                <Button
                                                    variant="contained"
                                                    className={classes.viewButton}
                                                >
                                                    View
                                                </Button>
                                        </Box>
                                    </Box>
                                ))}
                                <Box className={classes.sectionRow} style={{ marginTop: '20px' }}>
                                    <Typography className={classes.sectionText}>Assignment</Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.viewButton}
                                    >
                                        View
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                    <Button
                        variant="outlined"
                        className={classes.Button}
                        onClick={generateProgram}
                    >
                        Generate Program
                    </Button>
                </Box>

                <Box className={classes.rightContainer}>
                    <Typography
                        variant="h5"
                        className={classes.lessonTitle}
                        style={{ background: 'linear-gradient(to bottom, #FFA65A 20%, #FF763D 80%)',textAlign:'left', color: '#fff', fontWeight: 'bold', padding: '10px', paddingLeft:'25px' }}
                    >
                        LESSON 3: ONLINE REPUTATION
                    </Typography>
                <Box className={classes.cardsContainer}>
                    <Box style={{ marginTop: '20px', display: 'flex', padding: '0 20px', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff' }}>
                        <Box style={{ flex: 1, marginRight: '20px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Scene 1
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Understanding Online Reputation
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Your online reputation is the perception others have of you based on your digital presence, including social media profiles, websites, and any online interactions. Managing it effectively involves maintaining a positive image.
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #FFA65A 20%, #FF763D 80%)', color: '#fff', marginBottom: '10px', width: '100px' }}>
                                Edit
                            </Button>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #6DC2C4 20%, #5590C4 80%)', color: '#fff', width: '100px' }}>
                                View
                            </Button>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',  padding: '20px 20px', backgroundColor: '#F4F1ED' }}>
                        <Box style={{ flex: 1, marginRight: '20px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Scene 2
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                3 Steps to Manage Online Reputation
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Monitor Regularly: Keep track of your online presence through tools like Google Alerts and social media monitoring.
                                <br />
                                Engage Positively: Respond promptly and professionally to comments and reviews, addressing any negative feedback constructively.
                                <br />
                                Curate Content: Consistently post quality content that reflects your values and expertise, reinforcing a positive image.
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #FFA65A 20%, #FF763D 80%)', color: '#fff', marginBottom: '10px', width: '100px' }}>
                                Edit
                            </Button>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #6DC2C4 20%, #5590C4 80%)', color: '#fff', width: '100px' }}>
                                Delete
                            </Button>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',  padding: '0 20px', backgroundColor: '#fff' }}>
                        <Box style={{ flex: 1, marginRight: '20px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Scene 3
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Dummy Content
                            </Typography>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis augue non purus viverra efficitur.
                                Aliquam eget sapien viverra, feugiat augue eget, interdum sapien. Vivamus accumsan ante et odio tincidunt fermentum.
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #FFA65A 20%, #FF763D 80%)', color: '#fff', marginBottom: '10px', width: '100px' }}>
                                Edit
                            </Button>
                            <Button variant="contained" style={{ background: 'linear-gradient(to bottom, #6DC2C4 20%, #5590C4 80%)', color: '#fff', width: '100px' }}>
                                View
                            </Button>
                        </Box>
                    </Box>
                </Box>
                </Box>
            </div>
        </Container>
    )
}
export default SuggestedCourseCopy;