import React, {useRef, useEffect, useState} from 'react';
import { ArrowUpward } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { Box } from "@mui/material";
import useStyles from '../../../assets/styles/ChatBotStyles';
import Loader from '../Loader/Loader.comp';

const ChatBot = ({ data, options, actions}) => {
    const classes = useStyles();
    const chatEndRef = useRef(null);
    const inputRef = useRef(null);
    const [input, setInput] = useState('');
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const scrollToBottom = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (options.chatPreview !== false) {
            scrollToBottom();
        }
    }, [data.chats, options.chatPreview]);

    useEffect(() => {
        if (!isInputDisabled && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isInputDisabled]);


    const handleSendMessage = () => {
        if (options.chatType !== 'programOutline' && input.trim() === '') return;

        setIsInputDisabled(true);

        actions.sendMessageAction(input).then((response) => {

            setIsInputDisabled(false);
            setInput('');
            // if (response.data.programId) {
            //     setShowLoader(true);
            // }
        });
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    if (showLoader) {
        return <Loader />;
    }

    return (
        <Box className={classes.container} style={{bottom: options.bottom? '6%': '0%'}}>
            {options.chatPreview !== false && (
                <div style={{
                height: data?.chats?.length > 0 ? options.height : '0px',
                overflowY: 'auto',
                padding: '10px',
                borderRadius: '10px',
                transition: 'height 0.3s ease',
            }}>
                {data?.chats.map((chat, index) => (
                    <div key={index} style={{ textAlign: chat.type === 'user' ? 'right' : 'left' }}>
                        <p style={{
                            backgroundColor: chat.type === 'user' ? '#F2CB91' : '#e0e0e0',
                            display: 'inline-block',
                            padding: '10px',
                            borderRadius: '8px',
                            whiteSpace: 'pre-wrap',
                            maxWidth: '80%',
                            wordWrap: 'break-word',
                        }}>
                            {chat.text}
                        </p>
                    </div>
                ))}
                <div ref={chatEndRef} />
            </div>
                )}
            <Box className={classes.inputWrapper} sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextField
                    value={input}
                    multiline
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder={isInputDisabled ? '' : 'Type your message...'}
                    variant="outlined"
                    fullWidth
                    className={classes.inputField}
                    disabled={isInputDisabled}
                    inputRef={inputRef}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={handleSendMessage} className={classes.iconButton} disabled={isInputDisabled}>
                                <ArrowUpward />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default ChatBot;