import React, {useState} from 'react';
import {
    Container,
    Grid,
    Button,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import {Box, IconButton, TablePagination} from "@mui/material";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    addButton: {
        marginBottom: theme.spacing(2),
    },
    tableHeaderCell: {
        fontSize: '17px',
        fontWeight: 500,
    },
    tableCell: {
        color: '#11888B',
    },
    actionButton: {
        backgroundColor: '#052F44',
        color: 'white',
        borderRadius: '20px',
        fontSize: '13px',
        padding: '6px 12px',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#041F2D',
        },
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        width: '300px',
    },

}));

const ProgramsList = ({ data, actions, options }) => {
    const classes = useStyles();
    const { programs } = data;

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleCreateProgram = () => {
        if (actions.createProgramAction) {
            actions.createProgramAction();
        }
    };

    const handleViewProgram = (programId) => {
        if (actions.viewProgramAction) {
            actions.viewProgramAction(programId);
        }
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        setPage(0); // Reset to first page when search changes
    };

    const handleSearchClear = () => {
        setSearchText('');
        setPage(0);
    };

    const escapeRegExp = (value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    const filteredPrograms = programs.filter((program) => {
        const regex = new RegExp(escapeRegExp(searchText), 'i');
        return (
            regex.test(program.id) ||
            regex.test(program.title) ||
            regex.test(program.submissionDate) ||
            regex.test(program.package) ||
            regex.test(program.status)
        );
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container className={classes.container} maxWidth={false}>
            <Box display={'flex'} justifyContent="space-between" alignItems="center"  mb={2}>
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    onClick={handleCreateProgram}
                >
                    ADD NEW PROGRAM
                </Button>
                <div className={classes.searchContainer}>
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search…"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                    onClick={handleSearchClear}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                </div>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="programs table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderCell}>Program ID</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Program Title</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Submission Date</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Package</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Status</TableCell>
                            <TableCell className={classes.tableHeaderCell}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredPrograms.length > 0 ? (
                            filteredPrograms
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((program) => (
                                    <TableRow key={program.id}>
                                        <TableCell className={classes.tableCell}>{program.id}</TableCell>
                                        <TableCell className={classes.tableCell}>{program.title}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {program.submissionDate}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{program.package}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {program.status}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                className={classes.actionButton}
                                                variant="contained"
                                                onClick={() => handleViewProgram(program.id)}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No programs found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredPrograms.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Container>
    );
};

export default ProgramsList;