import React from 'react'

const DQGuide = () => {
    return (
        <>
            <div>
                <h1>DQ Guide</h1>
                <p>DQ Guide page.</p>
            </div>
        </>
    );
}

export default DQGuide;