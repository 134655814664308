import React from 'react'
import {Box, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rightContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        border: '2px solid #f3f1ed',
        borderRadius:'35px',
        alignItems: 'center',
        backgroundColor: '#fff',
        marginTop: '23px',
        marginLeft:'25px',
        marginRight:'45px',
        transition: 'all 0.3s ease',
        minHeight: '70vh',
        width: '100%',
    },
    lessonTitle: {
        marginBottom: theme.spacing(2),
        backgroundColor: '#F17632',
        borderRadius:'10px',
        color: '#fff',
        width: '100%',
        padding: '4px',
        textAlign: 'center',
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
    },
    Video:{
        width: '75%',
        height: '200px',
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        marginTop:'20px',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '24px',
        cursor: 'pointer',
    }
}));
const ProgramIntro = ({ selectedLesson}) =>{
    const classes = useStyles();
    return(
            <>
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                        <Typography variant="h5" className={classes.lessonTitle}>
                            {selectedLesson.title}
                        </Typography>
                        <div style={{ backgroundColor: '#F3F1EC', padding: '20px', width: '100%', position: 'relative', marginTop: '40px', border: '2px dotted #0097a7', borderRadius:'30px', }}>
                        <Typography variant="body1" style={{marginTop: '10px', color: '#000'}}>
                                {selectedLesson.lo}
                            </Typography>
                        </div>
                        <Box style={{ backgroundColor: '#F3F1EC', padding: '20px', width: '100%', position: 'relative', marginTop: '40px', border: '2px dotted #0097a7', borderRadius:'30px', }}>
                            <Box className={classes.tag}>
                                Introductory Video
                            </Box>
                            <Box style={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', marginTop: '20px' }}>
                                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
                                    Understanding Online Reputation
                                </Typography>
                                <div  style={{ width: '100%',display: 'flex', alignItems: 'center', }}>
                                    <Box style={{ width: '100%',display: 'flex', flexDirection:'column', alignItems: 'center', }}>
                                        <Typography variant="body1" style={{ marginBottom: '10px' }}>
                                            Your online reputation is the perception others have of you based on your digital presence, including social media profiles, websites, and any online interactions. Managing it effectively involves maintaining a positive image, addressing negative content swiftly, and consistently presenting yourself professionally across all online platforms.
                                        </Typography>
                                        <Box className={classes.Video}>
                                            ▶ Play Video
                                        </Box>
                                    </Box>

                                </div>
                            </Box>
                        </Box>
                    </Box>
            </>

    );
}

export default ProgramIntro
