import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputField: {
        '& .MuiInputBase-root': {
            color: '#002060',
            backgroundColor: '#fff',
            borderRadius: '8px',
        },
        '& .MuiOutlinedInput-multiline':{
            padding: '10.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#0397a6',
                borderWidth:'2px',
            },
            '&:hover fieldset': {
                borderColor: '#0397a6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0397a6',
            },
        },
    },
    iconButton: {
        color: 'white',
        backgroundColor: '#EE6628',
        '&:hover': {
            backgroundColor: '#EE6628',
        },
        padding: '10px',
        borderRadius: '50%',
    },
    container: {
        position: 'absolute',
        width: '80%',
        left: '10%',
        borderRadius:'7px',
        padding: '0px',
    },
    inputWrapper: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
}));
export default useStyles;