import React from 'react'

const TopRatedPrograms = () => {
    return (
        <>
            <div>
                <h1>Top Rated Programs</h1>
                <p>Top Rated Programs page.</p>
            </div>
        </>
    );
}

export default TopRatedPrograms;