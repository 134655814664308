
export const CURRENT_VERSION = '1.10';

export const TOAST_TYPES = {
    DANGER: 'danger',
    WARN: 'warning',
    SUCCESS: 'success',
    INFO: 'info'
};

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_seal_cl_auth_token',
    auth_user: 'dq_seal_cl_auth_user',
    refresh_token: 'dq_seal_cl_refresh_token',
    user_email: 'dq_seal_cl_user_email'
}

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const ACL_ENABLE_ENDPOINTS = []
export const URL_PERMS_MAP = {}

export const CHAT_TYPE = {
    USER: 'user',
    BOT: 'bot'
}

export const PROGRAM_STATUS = {
    DRAFT: 0,
    WITHDRAWN: 1,
    REJECTED: 2,
    IN_PROGRESS_COLLECT_DATA: 10,
    HALTED_COLLECT_DATA: 20,
    COLLECTED_DATA: 25,
    IN_PROGRESS_PROGRAM_CREATION: 30,
    PROGRAM_CREATED: 40,
    HALTED_PROGRAM_CREATION: 50,
    PENDING_OUTLINE_CREATION: 60,
    IN_PROGRESS_OUTLINE_CREATION: 65,
    OUTLINE_CREATED: 70,
    IN_PROGRESS_OUTLINE_UPDATE: 75,
    OUTLINE_COMPLETED: 80,
    PENDING_PAYMENT: 90,
    PENDING_APPROVAL: 100,
}

export const PROGRAM_STATUS_VERBOSE = {
    [PROGRAM_STATUS.DRAFT]: 'Program Creation Started',
    [PROGRAM_STATUS.WITHDRAWN]: 'Withdrawn',
    [PROGRAM_STATUS.REJECTED]: 'Rejected',
    [PROGRAM_STATUS.IN_PROGRESS_COLLECT_DATA]: 'Data Collection In Progress',
    [PROGRAM_STATUS.HALTED_COLLECT_DATA]: 'Halted',
    [PROGRAM_STATUS.COLLECTED_DATA]: 'Data Collected',
    [PROGRAM_STATUS.IN_PROGRESS_PROGRAM_CREATION]: 'Program Creation In Progress',
    [PROGRAM_STATUS.PROGRAM_CREATED]: 'Program Created',
    [PROGRAM_STATUS.HALTED_PROGRAM_CREATION]: 'Halted Program Creation',
    [PROGRAM_STATUS.PENDING_OUTLINE_CREATION]: 'Pending Outline Creation',
    [PROGRAM_STATUS.IN_PROGRESS_OUTLINE_CREATION]: 'Outline Creation In Progress',
    [PROGRAM_STATUS.OUTLINE_CREATED]: 'Outline Created',
    [PROGRAM_STATUS.IN_PROGRESS_OUTLINE_UPDATE]: 'Outline Update In Progress',
    [PROGRAM_STATUS.OUTLINE_COMPLETED]: 'Outline Completed',
    [PROGRAM_STATUS.PENDING_PAYMENT]: 'Pending Payment',
    [PROGRAM_STATUS.PENDING_APPROVAL]: 'Pending Payment Verification',
}


