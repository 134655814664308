import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    leftContainer: {
        padding: '10px',
        borderRadius: '8px',
        width: '32%',
        marginLeft:'50px',
        marginTop:'30px',
        marginRight: '20px',
        height:'100%',
    },
    rightContainer: {
        padding: '10px',
        width: '60%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        position:'relative',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    card: {
        marginBottom: '15px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        border:'1px solid #EE6628',
        cursor: 'pointer',
        color: '#002060',
    },
    cardContent: {
        color: '#002060',
        paddingBottom:'16px !important',
    },
    unitTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#EE6628',
        textAlign: 'center',
        padding: '10px',
    },
    lessonContainer: {
        paddingLeft: '20px',
    },
    sectionRow: {
        marginTop:'20px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        alignItems: 'center',
    },
    lessonRow: {
        marginTop:'0px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        alignItems: 'center',
    },
    viewButton: {
        backgroundColor: '#ef6528',
        color: '#fff',
        padding: '5px 15px',
        '&:hover': {
            backgroundColor: '#e44d1f',
        },
    },
    dropdownContainer: {
        paddingLeft: '20px',
        marginTop: '10px',
        borderLeft: '4px solid #ef6528',
    },
    sectionText: {
        fontWeight:'500',
        fontSize:'17px',
        color: '#002060',
        // fontWeight: 'bold',
    },
    selectedViewButton: {
        backgroundColor: '#c05615',
        '&:hover': {
            backgroundColor: '#af4d13',
        },
    },
    Button: {
        backgroundColor: '#ef6528',
        color: '#fff',
        marginTop: '20px',
        '&:hover': {
            backgroundColor: '#e44d1f',
        },
    },
    lessonTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333',
        paddingBottom: '50px',
    },
    sliderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px',
        backgroundColor: '#f3f1ec',
        borderRadius: '8px',
        position: 'relative',
    },
    slide: {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        width: '100%',
    },
    activeSlide: {
        display: 'block',
    },
    arrowButton: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#ef6528',
        fontSize: '30px',
        cursor: 'pointer',
        zIndex: 10,
    },
    prevArrow: {
        left: '10px',
    },
    nextArrow: {
        right: '10px',
    },
    slider: {
        width: '90%',
        // marginBottom: '20px',
        height: 8,
        color: '#ef6528 !important',
    },
    track: {
        height: 10,
        borderRadius: 4,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#ef6528 !important',
        border: '2px solid white',
        marginTop: -8,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: 'inherit',
        },
    },
    dropdownOptions:{
        backgroundColor:'#fff',

    }
}));
export default useStyles;