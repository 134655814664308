import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '20px',
        margin: '20px auto',
        width: '80%',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    title: {
        color: '#0397a6',
        textAlign: 'center',
        marginBottom: '50px !important',
        marginTop: '20px',
        fontSize: '30px',
    },
    cardsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
        width: '75%',
    },
    card: {
        backgroundColor: '#D8D8D8',
        borderRadius: '10px',
        padding: '15px',
        textAlign: 'center',
        width: '30%',
    },
    overview: {
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '16px',
        textAlign: 'left',
    },
    unit: {
        marginBottom: '10px',
        textAlign: 'left',
    },
    lesson: {
        paddingLeft: '20px',
        fontSize: '17px !important',
    },
    studentOutcomes: {
        paddingLeft: '30px',
        color: '#606060',
    },
    unitContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        marginTop: '30px',
        alignItems: 'flex-start',
    },
    button: {
        marginTop: '10px',
        backgroundColor: '#0397a6',
        justifyContent: 'left',
        outline: 'none',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#027A85',
        },
    },
}));

export default useStyles;