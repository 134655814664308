import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChatBotView from "../ChatBot/ChatBot.comp";
const useStyles = makeStyles((theme) => ({
    layout1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding:'0 30px',
        width:'100%',
        height: '90%',
    },
    layout1Content: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        maxWidth: '1200px',
        Height: '100%',
    },
    layout2: {
        backgroundColor: (props) => props.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin:'0 40px',
        height: '90%',
        borderRadius:'25px',
        padding: theme.spacing(2),
    },
    layout2Left: {
        textAlign: 'center',
    },
    layout2Image: {
        height: (props)=> props.height,
        margin:'40px',
        borderRadius:'20px',
    },
    layout2Title: {
        fontSize: '35px',
        fontWeight: 'bold',
        marginTop: '10px',
        color:'#EEFF41',
    },
    layout2Right: {
        justifyContent:'center',
        paddingRight:'20px',
        display: 'flex',
        flexDirection: 'column',
    },
    layout3: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        height: '90%',
        padding: theme.spacing(2),
        borderRadius:'25px',
        backgroundColor: (props) => props.backgroundColor,
    },

    layout3Title: {
        marginTop:'20px',
        fontSize: '35px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        color: '#EEFF41',
    },

    layout3Content: {
        marginTop:'70px',
        display:'flex',
        flexDirection:'row',
        width: '100%',
        textAlign: 'left',
    },

    layout3List: {
        listStyleType: 'disc',
        margin: 0,
    },

    layout3ListItem: {
        marginBottom: theme.spacing(1),
    },

    layout3Card: {
        backgroundColor: '#F9B143',
        border: '2px dashed white',
        color: 'white',
        padding: theme.spacing(2),
        borderRadius: '8px',
        textAlign: 'center',
        margin:'10px',
        marginBottom: theme.spacing(2),
        width: '100%',
    },

    layout3CardText: {
        fontSize: '18px',
        fontWeight: 'normal',
    },

    layout4: {
        backgroundColor: (props) => props.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        height:'90%',
        borderRadius: '25px',
        padding: theme.spacing(2),
    },

    layout4Left: {
        width: '47%',
        textAlign: 'center',
        paddingLeft: '20px',
    },

    layout4Title: {
        fontSize: '35px',
        fontWeight: 'bold',
        marginTop: '10px',
        color: '#EEFF41',
    },

    layout4Paragraph: {
        color: '#fff',
        fontSize: '18px',
        fontWeight:'bold',
    },

    layout4Right: {
        width: '50%',
        marginTop: '10px !important',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
    },

    layout4Image: {
        height: '311px',
        borderRadius: '20px !important',
        margin: '25px',
    },
    layout6: {
        width: '90%',
        height: '100%',
        backgroundColor: (props) => props.backgroundColor,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '25px',
    },
    layout6Title: {
        fontSize: '35px',
        color: '#FFEB3B',
        fontWeight: 'bold',
        marginTop:'13px',
        marginBottom:'10px',
    },
    layout6Content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
    },
    layout6Column: {
        backgroundColor: '#EF6528',
        width: '35%',
        minHeight: '405px',
        maxHeight: '450px',
        flexGrow:'1',
        minWidth: '200px',
        marginBottom:'30px',
        margin:'11px',
        borderRadius: '25px',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFEB3B',
    },
    layout6CardNumber: {
        fontSize: '50px',
        fontWeight: 'bold',
        color: '#FFEB3B',
    },
    layout6CardTitle: {
        fontSize: '24px',
        color: '#fff',
        margin: theme.spacing(1, 0),
    },
    layout6CardDescription: {
        fontSize: '18px',
        color: '#FFEB3B',
    },
    layout7: {
        backgroundColor: (props) => props.backgroundColor,
        padding: theme.spacing(4),
        width: '90%',
        height:'90%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        color: '#fff',
    },
    layout7Title: {
        fontSize: '35px',
        fontWeight: 'bold',
        color: '#FFEB3B',
        textAlign: 'left',
        marginBottom: theme.spacing(3),
    },
    layout7List: {
        listStyleType: 'decimal',
        paddingLeft: '55px',
        margin: 0,
        fontSize:'17px',
        textAlign:'left',
    },
    layout7ListItem: {
        marginBottom: theme.spacing(2),
        color: '#fff',
        '& b': {
            fontWeight: 'bold',
            color: '#FFEB3B',
        },
    },
    layout8: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width:'90%',
        height: '90%',
        backgroundColor: (props) => props.backgroundColor,
        textAlign: 'center',
        borderRadius: '25px',
    },
    layout8Title: {
        fontSize: '35px',
        fontWeight: 'bold',
        color: '#fff',
    },

    layout9: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: (props) => props.backgroundColor,
        width: '90%',
        height:'90%',
        borderRadius:'25px',
        padding: theme.spacing(3),
    },
    layout9Left: {
        width: '45%',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems:'center',
        padding: theme.spacing(2),
    },
    layout9Right: {
        width: '60%',
        display: 'flex',
        justifyContent: 'center',
    },
    layout9Image: {
        height: '335px',
    },
    layout9Button: {
        backgroundColor: '#fff',
        color: '#EF6528',
        width:'100px',
        border: '1px solid #EF6528',
        padding: theme.spacing(1, 1),
        fontSize: '16px',
        cursor: 'pointer',
        marginTop: theme.spacing(2),
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    layout10: {
        position:'relative',
        paddingBottom:'20px',
        backgroundColor: (props) => props.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        padding: theme.spacing(3),
        borderRadius:'25px',
        minHeight: '400px',
    },

}));
const Layout1 = ({ videoSrc }) => {
    const classes = useStyles();
    return (
        <div className={classes.layout1}>
            <div className={classes.layout1Content}>
                <video width="100%" height="auto" controls style={{borderRadius:'20px'}}>
                    <source src={videoSrc} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};
//slide 2 & 5
const LeftImageLayout = ({ imageSrc, title, paragraph, backgroundColor, height }) => {
    const classes = useStyles({ backgroundColor, height });
    return (
        <div className={classes.layout2}>
            <div className={classes.layout2Left}>
                <img src={imageSrc} alt="Slide" className={classes.layout2Image} />
            </div>
            <div className={classes.layout2Right}>
                <h2 className={classes.layout2Title}>{title}</h2>
                <h4 style={{color:'#fff'}}>{paragraph}</h4>
            </div>
        </div>
    );
};
const Layout3 = ({ title, cardContent, backgroundColor }) => {
    const classes = useStyles({ backgroundColor });
    return (
        <div className={classes.layout3}>
            <h1 className={classes.layout3Title}>{title}</h1>
            <div className={classes.layout3Content}>
                {cardContent.map((card, index) => (
                    <div key={index} className={classes.layout3Card}>
                        <div className={classes.layout3CardText}>{card}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
//slide 4,9,11
const RightImageLayout = ({title, paragraph, imageSrc, backgroundColor,showButton }) => {
    const classes = useStyles({ backgroundColor });
    return (
        <div className={classes.layout4}>
            <div className={classes.layout4Left}>
                {/* Conditionally render title or paragraph */}
                {title ? <h2 className={classes.layout4Title}>{title}</h2> : null}
                {paragraph && <h4 className={classes.layout4Paragraph}>{paragraph}</h4>}
                {showButton && (
                    <button className={classes.layout9Button}>Done</button>
                )}
            </div>
            <div className={classes.layout4Right}>
                <img src={imageSrc} alt="Slide" className={classes.layout4Image}/>
            </div>
        </div>
    );
};
const Layout6 = ({title, cardContent, backgroundColor}) => {
    const classes = useStyles({backgroundColor});
    return (
        <div className={classes.layout6}>
            <h4 className={classes.layout6Title}>{title}</h4>
            <div className={classes.layout6Content}>
                {cardContent.map((card, index) => (
                    <div key={index} className={classes.layout6Column}>
                        <div className={classes.layout6CardNumber}>{index + 1}</div>
                        <div className={classes.layout6CardTitle}>{card.title}</div>
                        <div className={classes.layout6CardDescription}>{card.text}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
const Layout7 = ({ title, listItems, backgroundColor}) => {
    const classes = useStyles({ backgroundColor });
    return (
        <div className={classes.layout7}>
            <h2 className={classes.layout7Title}>{title}</h2>
            <ol className={classes.layout7List}>
                {listItems.map((item, index) => (
                    <li key={index} className={classes.layout7ListItem}>
                        <b>{item.title}</b> - {item.description}
                    </li>
                ))}
            </ol>
        </div>
    );
};
const Layout8 = ({ backgroundColor }) => {
    const classes = useStyles({ backgroundColor });
    return (
        <div className={classes.layout8}>
            <h2 className={classes.layout8Title}>Let's Test What You Have Learned</h2>
        </div>
    );
};
const Layout10 = ({ data, options, actions, backgroundColor }) => {
    const classes = useStyles({ backgroundColor });
    return (
        <div className={classes.layout10}>
            <ChatBotView
                data={data}
                actions={actions}
                options={options}
            />
        </div>
    );
};


export {Layout1, LeftImageLayout,RightImageLayout, Layout3, Layout6, Layout7, Layout8,Layout10};
