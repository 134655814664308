import React from 'react'

const ProgramResources = () => {
    return (
        <>
            <div>
                <h1>Program Resources</h1>
                <p>Program Resources page for a specific program.</p>
            </div>
        </>
    );
}

export default ProgramResources;