import React from 'react'

const DynamicLearning = () => {
    return (
        <>
            <div>
                <h1>Program Dynamic Learning</h1>
                <p>Dynamic Learning page for a specific program.</p>
            </div>
        </>
    );
}

export default DynamicLearning;