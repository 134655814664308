import React, {useState} from 'react'
import ProgramEditView from "../../views/ver_24.10/ProgramEdit/ProgramEdit.view"

const ProgramEdit = () => {
    const [curriculum, setCurriculum] = useState([
        {
            unitId: 'unit1',
            title: 'Unit 1: Aware Digital Citizens',
            lessons: [
                { lessonId: 'lesson1.1', title: 'Lesson 1: Online Personas (10 mins)', lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
                { lessonId: 'lesson1.2', title: 'Lesson 2: Digital Presence (10 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
                { lessonId: 'lesson1.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
                { lessonId: 'lesson1.4', title: 'Lesson 4: Global Digital Citizenship (7 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
            ],
        },
        {
            unitId: 'unit2',
            title: 'Unit 2: Engaging Digital Learners',
            lessons: [
                { lessonId: 'lesson2.1', title: 'Lesson 1: Online Personas (5 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
                { lessonId: 'lesson2.2', title: 'Lesson 2: Digital Presence (10 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
                { lessonId: 'lesson2.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)',lo:'In this section, you`ll find a 1-2 minutes introductory video tailored to your selected target audience. This video serves as a teaser to give an overview of the learning components and topics covered in the lesson. It’s a great way to set expectations and get learners engaged before diving into the lesson.' },
            ],
        },
    ]);
    const data = {
        curriculum,
    };

    const options={
    };

    const actions={
    }
    return (
        <>
            <div>
                <ProgramEditView data={data} options={options} actions={actions}/>
            </div>
        </>
    );
}

export default ProgramEdit;