import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {getAllCurriculums, selectCurriculum} from "../../services/pbdq_v2.service";
import {ALL_CURRICULA, CURRICULUM_SKILLS_BY_CODE} from "../../data/curriculums.data";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 50px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minHeight: '75vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        alignItems:'center',
        justifyContent:'center',
    },
    arrowButton: {
        position: 'absolute',
        top: '60%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        fontSize: '3rem',
        color: '#ee6628',
        zIndex: 1000,
    },
    leftArrow: {
        marginRight: '7px',
        paddingLeft: '11px',
        left: '-35px',
        '&:hover': {
            color: '#ee6628',
        },
    },
    rightArrow: {
        marginLeft:'10px',
        right: '-35px',
        '&:hover': {
            color: '#ee6628',
        },
        '&:disabled': {
            color: '#808080',
            cursor: 'default',
        },
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(6),
        flexWrap: 'wrap',
    },
    card: {
        border: '1px solid #ee6628',
        borderRadius: '10px',
        backgroundColor: "transparent",
        position: 'relative',
        padding: '0px !important',
        flex: 1,
        margin: '25px 27px',
        cursor: "pointer",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        '&:hover': {
            transform: "scale(1.05)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
        '&:hover $cardHeader': {
            backgroundColor: "#0097a7",
            color: '#fff',
            border: '2px solid #0097a7',
        },
        '&:hover $cardHeader2': {
            backgroundColor: "#0097a7",
            color: '#fff',
            border: '2px solid #0097a7',
        },
        '&:hover $cardHeaderText': {
            color: '#fff',
        },
        '&:hover $cardHeaderTitle': {
            color: '#fff',
        },
    },
    descriptionLess: {
        height: '140px',
    },
    cardHeaderTitle: {
        fontSize: '1.3rem',
        fontWeight: "400",
        color: "#ee6628",
    },
    cardHeader: {
        height: '170px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        backgroundColor: "#fff",
        padding: '16px',
        transition: "background-color 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out",
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardHeader2: {
        height: '170px',
        // border: '1px solid #ee6628',
        borderRadius: '9px',
        backgroundColor: "#fff",
        padding: theme.spacing(2),
        transition: "background-color 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out",
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardHeaderText: {
        fontWeight: "400",
        color: "#ee6628",
        fontSize: '1.5rem',
    },
    cardDescription: {
        minHeight: '275px',
        backgroundColor: '#F3F1EC',
        padding: theme.spacing(2),
        paddingTop: '40px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        flexGrow: 1,
        borderTop: 'none',
        textAlign: 'center',
        overflow: 'hidden',
        display: 'none',
    },
    showDescription: {
        display: 'block',
    },
    selectedCard: {
        '& $cardHeader': {
            backgroundColor: "#0097a7",
            border: '1px solid #0097a7',
            color: '#fff',
        },
        '& $cardHeader2': {
            backgroundColor: "#0097a7",
            border: '1px solid #0097a7',
            color: '#fff',
        },
        '& $cardHeaderText': {
            color: '#fff',
        },
        '& $cardHeaderTitle': {
            color: '#fff',
        },
    },
    disabledCard: {
    opacity: '0.5',
    cursor: 'not-allowed',
},
}));

const ProgramCreation = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [answers, setAnswers] = useState({});
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [questions, setQuestions] = useState([
        {
            title: "Who’s learning?",
            subtitle: "(You can select more than one.)",
            options: [
                { title: "Pre-School/ Kindergarten", category: 'early' },
                { title: "Students Grades 1 - 2", category: 'early' },
                { title: "Students Grades 3 - 4", category: 'middle' },
                { title: "Students Grades 5 - 6", category: 'middle' },
                { title: "Students Grades 7 - 9", category: 'middle' },
                { title: "Students Grades 10 - 12", category: 'middle' },
                { title: "Tertiary Students", category: 'adult' },
                { title: "Company Leadership", category: 'adult' },
                { title: "Company Employees", category: 'adult' },
                { title: "General Public", category: 'adult' },
                { title: "Parents", category: 'adult' },
                { title: "Teachers/ Educators", category: 'adult' }
            ]
        },
        {
            title: "How would you prefer to design the curriculum?",
            options: [
                {
                    title: "I want to adopt a popular digital skills curriculum.",
                    description: "",
                    nextQuestion: null
                },
                {
                    title: "I will build my own digital skills curriculum.",
                    description: "",
                }
            ]
        },
    ]);

    async function fetchAndMapCurriculum() {
        setLoading(true);

        try {
            const result = await getAllCurriculums();
            const curriculumData = result.data;
            const mappedCurriculumOptions = curriculumData.map(curriculum => ({
                title: curriculum.full_name,
                description: curriculum.definition
            }));

            const nextQuestion = {
                title: "Select From a Popular Digital Skills Curriculum",
                options: mappedCurriculumOptions
            };

            console.log('Next question data ->', nextQuestion);
            return nextQuestion;

        } catch (e) {
            console.error('Error fetching curriculum data:', e);
        } finally {
            setLoading(false);
        }
    }
    const isOptionDisabled = (option) => {
        if (currentQuestion === 0) {
            if (!selectedCategory) return false;
            return selectedCategory !== option.category;
        }
        return false;
    };

    const getCurriculumCodeFromTitle = (title) => {
        const curriculum = ALL_CURRICULA.find(curriculum => curriculum.full_name === title);
        return curriculum ? curriculum.code : null;
    };

    const handleOptionClick = (option, index) => {
        // setAnswers((prevAnswers) => {
        //     const currentAnswers = prevAnswers[currentQuestion] || [];
        //     const updatedAnswers = currentAnswers.includes(option.title)
        //         ? currentAnswers.filter((ans) => ans !== option.title)
        //         : [...currentAnswers, option.title];
        //
        //     if (currentQuestion === 0) {
        //                     let newCategory = selectedCategory;
        //                     if (updatedAnswers.length === 0) {
        //                         newCategory = null;
        //                     } else if (!currentAnswers.includes(option.title)) {
        //                         newCategory = option.category;
        //                     }
        //                     setSelectedCategory(newCategory);
        //                 }
        //
        //     return {
        //         ...prevAnswers,
        //         [currentQuestion]: updatedAnswers
        //     };
        // });

        setAnswers((prevAnswers) => {
            let updatedAnswers = [...(prevAnswers[currentQuestion] || [])];
            const currentAnswers = updatedAnswers.map(answer => answer.title);

            if (currentAnswers.includes(option.title)) {
                updatedAnswers = updatedAnswers.filter(answer => answer.title !== option.title);
            } else {
                updatedAnswers = updatedAnswers.filter(answer => answer.category === option.category);
                updatedAnswers.push(option);
            }

            if (currentQuestion === 0) {
                            let newCategory = selectedCategory;
                            if (updatedAnswers.length === 0) {
                                newCategory = null;
                            } else if (!currentAnswers.includes(option.title)) {
                                newCategory = option.category;
                            }
                            setSelectedCategory(newCategory);
                        }

            return {
                ...prevAnswers,
                [currentQuestion]: updatedAnswers
            };
        });

        if (currentQuestion === 1) {
            if (index === 0 && !answers[currentQuestion]?.includes(option.title)) {
                setQuestions((prevQuestions) => [...prevQuestions, questions[currentQuestion].options[0].nextQuestion]);
                setCurrentQuestion((prevQuestion) => prevQuestion + 1);
            } else if (index === 1) {
                history.push('/program_selection');
            }
        } else if (currentQuestion === 2) {
            const curriculumCode = getCurriculumCodeFromTitle(option.title);
            const selectedSkill = [option.title];
            setSelectedSkills(selectedSkill);
            history.push({
                // pathname: '/suggested_course',
                pathname: '/suggested_course_copy',
                state: { selectedSkills: selectedSkill, curriculumCode,
                }

            });
            console.log(curriculumCode);
        }
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        }
    };

    const handleBack = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion((prevQuestion) => prevQuestion - 1);
        }
    };

    const renderOptions = (options) => (
        <Grid container className={classes.cardContainer} spacing={4} justify="center" alignItems="center">
            {questions[currentQuestion].options.map((option, idx) => (
                <Grid
                    item
                    key={idx}
                    className={`${classes.card} 
                    ${!option.description ? classes.descriptionLess : ''} 
                    ${answers[currentQuestion]?.some(selected => selected.title === option.title) ? classes.selectedCard : ''}`}
                    align="center"
                    onClick={() => handleOptionClick(option, idx)}
                    style={{ pointerEvents: 'auto' }}
                >
                    <div className={`${!option.description ? classes.cardHeader2 : classes.cardHeader}`}>
                        <Typography variant="h6" style={{ fontWeight: "500" }} className={`${!option.description ? classes.cardHeaderTitle : classes.cardHeaderText}`}>
                            {option.title}
                        </Typography>
                    </div>
                    {option.description && (
                        <div className={`${classes.cardDescription} ${option.description ? classes.showDescription : ''}`}>
                            <Typography variant="body1">
                                {option.description}
                            </Typography>
                        </div>
                    )}
                </Grid>
            ))}
        </Grid>
    );

    useEffect(() => {
        const fetchCurriculumData = async () => {
            const nextQuestion = await fetchAndMapCurriculum();
            setQuestions(prevQuestions => {
                const updatedQuestions = [...prevQuestions];
                updatedQuestions[1].options[0].nextQuestion = nextQuestion;
                return updatedQuestions;
            });
        };

        fetchCurriculumData();
    }, []);
    return (
        <Container className={classes.container} maxWidth="lg">
            <ArrowBackIosIcon
                className={`${classes.arrowButton} ${classes.leftArrow}`}
                onClick={handleBack}
                style={{
                    visibility: currentQuestion === 0 ? 'hidden' : 'visible',
                    borderRadius: '50%',
                    border: '2px solid #ee6628',
                    backgroundColor: '#fff',
                    boxSizing: 'border-box',
                    color: currentQuestion === 0 ? '#808080' : '#ee6628',
                }}
            />

            <ArrowForwardIosIcon
                className={`${classes.arrowButton} ${classes.rightArrow}`}
                onClick={() => {
                    if (answers[currentQuestion] && answers[currentQuestion].length > 0) {
                        handleNext();
                    }
                }}
                style={{
                    color: !answers[currentQuestion] || answers[currentQuestion].length === 0 ? '#808080' : '#0097a7',
                    cursor: !answers[currentQuestion] || answers[currentQuestion].length === 0 ? 'default' : 'pointer',
                    borderRadius: '50%',
                    border: !answers[currentQuestion] || answers[currentQuestion].length === 0 ? '2px solid #808080' : '2px solid #0097a7',
                    padding: '8px',
                    backgroundColor: '#fff',
                    boxSizing: 'border-box',
                }}
                disabled={!answers[currentQuestion] || answers[currentQuestion].length === 0}
            />

            <Typography color="inherit" variant="h4" style={{ textAlign: "center", marginTop: '40px' }}>
                {questions[currentQuestion].title}
            </Typography>
            {questions[currentQuestion].subtitle && (
                <Typography color="inherit" variant="h4" style={{ textAlign: "center", color: "#ee6628", fontStyle: 'italic' }}>
                    {questions[currentQuestion].subtitle}
                </Typography>
            )}
            {renderOptions(questions[currentQuestion].options)}

            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default ProgramCreation;