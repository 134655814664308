import React, {useEffect, useState} from 'react'
import {Box, Card, CardContent, Container, Divider, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Loader from "../../../ui_library/ver_24.10/Loader/Loader.comp";
import ProgramPreAssessment from "./ProgramPreAssessment.comp";
import LearningContent from "./LearningContent.comp";
import ProgramPostAssessment from "./ProgramPostAssessment.comp";
import ApplicationActivity from "./ApplicationActivity.comp";
import Introduction from "./Introduction.comp";
import {
    getProgramState,
    selectCurriculum,
    selectDigitalSkills,
    updateProgramState
} from "../../../services/pbdq_v2.service";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingTop:'30px',
        display: 'flex',
        flexDirection: 'column',
        margin:'0px',
        transition: 'all 0.3s ease',
        justifyContent: 'start',
        alignItems: 'center',
        maxWidth: '100%',
        minHeight: '100vh',
    },
    mainTitle: {
        color: '#1b3867',
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        fontWeight: 'bold',
    },
    leftContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '12px',
        alignItems: 'center',
        flexDirection: 'column',
        transition: 'all 0.3s ease',
        minHeight: '70vh',
        width: '50%',
    },
    sectionRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        position: 'relative',
    },
    sectionText: {
        fontSize: '19px',
        color:'#1b3867',
    },
    rightContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        border: '2px solid #f3f1ed',
        borderRadius:'35px',
        alignItems: 'center',
        backgroundColor: '#fff',
        marginTop: '23px',
        marginLeft:'25px',
        marginRight:'45px',
        transition: 'all 0.3s ease',
        minHeight: '70vh',
        width: '100%',
    },
    card: {
        width: '450px',
        margin: theme.spacing(2),
        textAlign: 'left',
        background:' linear-gradient(to bottom, #F17430 5%, #FECE60 50%)',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    lesson: {
        color:'#002060',
        margin: theme.spacing(1, 0),
        padding: '0 20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    divider: {
        backgroundColor: 'white',
        width: '100%',
        margin:'10px 0px',
        height: '4px !important',
        marginRight: '20px !important',
        borderRadius: '2px',
    },
    viewButton: {
        color: 'white',
        backgroundColor: '#4294A5',
        '&:hover': {
            backgroundColor: '#06215B',
            color: '#fff',
        },
    },
    selectedViewButton: {
        backgroundColor: '#06215B',
        '&:hover': {
            backgroundColor: '#06215B',
        },
    },
    lessonTitle: {
        marginBottom: theme.spacing(2),
        backgroundColor: '#F17632',
        borderRadius:'10px',
        color: '#fff',
        width: '100%',
        padding: '4px',
        textAlign: 'center',
    },
    objectiveCard: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0px',
        boxShadow: 'none',
    },
    objectiveContent: {
        textAlign: 'left',
        marginRight: '25px',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    button: {
        color: '#fff',
    },
    Button:{
        border:'2px solid #4294A5',
        color:'#4294A5',
    },
    assignment: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        width:'430px',
        marginBottom:'20px',
    },
    lessonContainer: {
        marginBottom: theme.spacing(2),
    },
    dropdownContainer: {
        backgroundColor: '#f3f1ed',
        padding: theme.spacing(2),
        // borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        zIndex: 1000,
        maxHeight: '300px',
        overflowY: 'auto',
    },
    tag:{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        backgroundColor: '#06215B',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
    },
    Video:{
        width: '75%',
        height: '200px',
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        marginTop:'20px',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '24px',
        cursor: 'pointer',
    }
}));

const SuggestedCourse= () =>{
    const location = useLocation();
    const { selectedSkills, curriculumCode } = location.state || { selectedSkills: [], curriculumCode: null };
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedSection, setSelectedSection] = useState('Student Outcomes');
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [selectedCurriculum, setSelectedCurriculum] = useState([]);
    const skillTitle = selectedSkills.length > 0 ? selectedSkills[0] : 'Digital Citizen';
    const [assignmentData, setAssignmentData] = useState([]);
    useEffect(() => {
        const fetchCurriculumData = async () => {
            try {
                if (curriculumCode) {
                    setLoading(true);
                    const result = await selectCurriculum(curriculumCode);
                    if (result.data && result.data.programState) {
                        const { curriculum ,user_program_units} = result.data.programState;
                        setSelectedCurriculum(user_program_units);
                    }
                }
            } catch (error) {
                console.error('Error selecting curriculum:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCurriculumData();
    }, [curriculumCode]);

    useEffect(() => {
        const prepareCurriculumFromDQCs = async () => {
            try {
                if (selectedSkills.length > 0) {
                    setLoading(true);
                    console.log('nav:log -----> selectedSkills -> ', selectedSkills);
                    // TODO:NAV:LOG skills data is not complete, just passing the title here, needed dqc_no array to prepare custom curriculum.
                    const result = await selectDigitalSkills(curriculumCode);
                    if (result.data && result.data.programState) {
                        const { curriculum ,user_program_units} = result.data.programState;
                        setSelectedCurriculum(user_program_units);
                    }
                }
            } catch (error) {
                console.error('Error preparing curriculum:', error);
            } finally {
                setLoading(false);
            }
        };
        prepareCurriculumFromDQCs();
    }, [selectedSkills]);


    console.log(selectedCurriculum, "&&&&&&&&&&&&&&&&&&&");

    const handleViewContent = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedAssignment(null);
        setSelectedSection(section);
    };
    // const handleRemoveOutcome = (index) => {
    //     const updatedOutcomes = selectedLesson.studentOutcomes.filter((_, i) => i !== index);
    //     setSelectedLesson(prevLesson => ({ ...prevLesson, studentOutcomes: updatedOutcomes }));
    // };
    const handleRemoveOutcome = async (index) => {
        try {
            const result = await getProgramState();
            const { programState } = result.data;

            if (!selectedLesson || !selectedLesson.student_outcomes) {
                console.error('Selected lesson or student outcomes are undefined');
                return;
            }
            const updatedOutcomes = selectedLesson.student_outcomes.filter((_, i) => i !== index);

            if (updatedOutcomes.length === selectedLesson.student_outcomes.length) {
                console.error('Failed to remove outcome, index not found');
                return;
            }
            const updatedProgramUnits = programState.user_program_units.map(unit => {
                if (unit.gsm_no === selectedLesson.gsm_no) {
                    return {
                        ...unit,
                        student_outcomes: updatedOutcomes
                    };
                }
                return unit;
            });
            const response = await updateProgramState(updatedProgramUnits);
            if (response.data.success) {
                console.log('Outcome removed and saved to local store.');
                setSelectedLesson(prevLesson => ({
                    ...prevLesson,
                    student_outcomes: updatedOutcomes
                }));
            } else {
                console.error('Failed to update program state');
            }
            console.log('Updated program units:', updatedProgramUnits);

        } catch (error) {
            console.error('Error removing student outcome:', error);
        }
    };

    const handleSave = async () => {
        try {
            const result = await getProgramState();
            const { programState } = result.data;

            const updatedProgramUnits = programState.user_program_units.map(unit => {
                if (unit.gsm_no === selectedLesson.gsm_no) {
                    return { ...unit, student_outcomes: selectedLesson.student_outcomes };
                }
                return unit;
            });

            const response = await updateProgramState(updatedProgramUnits);
            if (response.data.success) {
                console.log('Outcomes saved successfully');
            } else {
                console.error('Failed to save outcomes');
            }
        } catch (error) {
            console.error('Error saving outcomes:', error);
        }
    };

    if (loading) {
        return (
            <Box className={classes.loaderContainer}>
                <Loader />
            </Box>
        );
    }
    const toggleDropdown = (lessonIndex, cardIndex) => {
        setOpenDropdowns(prevState => ({
            ...prevState,
            [`${lessonIndex}-${cardIndex}`]: !prevState[`${lessonIndex}-${cardIndex}`]
        }));
    };
    const handleViewOutcomes = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };
    const handleViewLesson = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };
    const handleViewActivity = (lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(null);
    };

    const handleViewPreAssignment = (preAssessmentData, lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setAssignmentData(preAssessmentData);
        setSelectedAssignment(assignmentData);
    };

    const handleViewPostAssignment = (postAssessmentData, lesson, section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setSelectedAssignment(assignmentData);
        setAssignmentData(postAssessmentData);
    };
    const generateProgram = () => {
        setLoading(true);
        setTimeout(() => {
            history.push({
                pathname: '/program_name',
            });
        }, 2000);
    };
    return(
        <Container className={classes.mainContainer}>
            <Typography variant="h5" style={{ margin: '20px 0', fontWeight: 'bold', color: '#06215B' }}>
                Suggested {skillTitle} ID Course
            </Typography>
            <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                <Box className={classes.leftContainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        selectedCurriculum.length > 0 ? (
                            selectedCurriculum.map((unit, unitIndex) => (
                                <Card className={classes.card} key={`unit-${unitIndex}`}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="h6" component="h2" style={{ marginTop: '20px', color: '#fff' }}>
                                            {unit.verbose_name}
                                        </Typography>
                                        <Divider className={classes.divider} style={{ height: '4px !important' }} />
                                        {unit.learning_objectives.map((lesson, lessonIndex) => (
                                            <Box className={classes.lessonContainer} key={`lesson-${lessonIndex}`}>
                                                <Box className={classes.sectionRow} style={{ borderBottom: '2px solid #fff', paddingBottom: '8px' }}>
                                                    <Typography className={classes.sectionText} style={{ cursor: 'pointer' }} onClick={() => handleViewOutcomes(lesson, 'Student Outcomes')}>
                                                        {lesson.verbose_name}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.viewButton}
                                                        onClick={() => toggleDropdown(lessonIndex,unitIndex )}
                                                    >
                                                        View
                                                    </Button>
                                                </Box>


                                                {openDropdowns[`${lessonIndex}-${unitIndex}`] && (
                                                    <Box className={classes.dropdownContainer}>
                                                        <Box className={classes.sectionRow}>
                                                            <Typography className={classes.sectionText}>Introduction</Typography>
                                                            <Button
                                                                variant="contained"
                                                                className={`${classes.viewButton} ${selectedSection === 'Introduction' && selectedLesson === lesson ? classes.selectedViewButton : ''}`}
                                                                onClick={() => handleViewContent(lesson, 'Introduction')}
                                                            >
                                                                View
                                                            </Button>
                                                        </Box>
                                                        <Box className={classes.sectionRow}>
                                                            <Typography className={classes.sectionText}>Pre Assessment</Typography>
                                                            <Button
                                                                variant="contained"
                                                                className={`${classes.viewButton} ${selectedSection === 'Pre Assessment' && selectedLesson === lesson ? classes.selectedViewButton : ''}`}
                                                                onClick={() => handleViewPreAssignment(lesson.pre_assessment, lesson, 'Pre Assessment')}
                                                            >
                                                                View
                                                            </Button>
                                                        </Box>
                                                        <Box className={classes.sectionRow}>
                                                            <Typography className={classes.sectionText}>Learning Content</Typography>
                                                            <Button
                                                                variant="contained"
                                                                className={`${classes.viewButton} ${selectedSection === 'Learning Content' && selectedLesson === lesson ? classes.selectedViewButton : ''}`}
                                                                onClick={() => handleViewLesson(lesson, 'Learning Content')}
                                                            >
                                                                View
                                                            </Button>
                                                        </Box>
                                                        <Box className={classes.sectionRow}>
                                                            <Typography className={classes.sectionText}>Application Activity</Typography>
                                                            <Button
                                                                variant="contained"
                                                                className={`${classes.viewButton} ${selectedSection === 'Application Activity' && selectedLesson === lesson ? classes.selectedViewButton : ''}`}
                                                                onClick={() => handleViewActivity(lesson, 'Application Activity')}
                                                            >
                                                                View
                                                            </Button>
                                                        </Box>
                                                        <Box className={classes.sectionRow}>
                                                            <Typography className={classes.sectionText}>Post Assessment</Typography>
                                                            <Button
                                                                variant="contained"
                                                                className={`${classes.viewButton} ${selectedSection === 'Post Assessment' && selectedLesson === lesson ? classes.selectedViewButton : ''}`}
                                                                onClick={() => handleViewPostAssignment(lesson.post_assessment, lesson, 'Post Assessment')}
                                                            >
                                                                View
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <Typography>No curriculum data available</Typography>
                        ))}
                    <Button
                        variant="outlined"
                        className={classes.Button}
                        onClick={generateProgram}
                    >
                        Generate Program
                    </Button>
                </Box>

                <Box className={classes.rightContainer}>
                {selectedLesson && selectedSection === 'Student Outcomes' && (
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center',width:'100%'}}>
                        <Typography variant="h5" className={classes.lessonTitle}>
                            {selectedLesson.verbose_name}
                        </Typography>
                        <div style={{paddingLeft: '20px',width:'100%'}}>
                            <Typography variant="body1" style={{marginTop: '10px', color: '#ef6528'}}>
                                <strong>Learning Objectives:</strong>
                            </Typography>
                            <Typography variant="body1" style={{marginTop: '10px', color: '#000'}}>
                                 {selectedLesson.definition}
                            </Typography>
                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Typography variant="body1" style={{marginTop: '10px', color: '#ef6528'}}>
                                    <strong>Estimated Learning Time:  </strong>
                                </Typography>
                                <Typography variant="body1" style={{ marginTop: '10px',marginLeft:'10px', color: '#000' }}>
                                     {
                                        selectedLesson.student_outcomes.reduce((total, outcome) =>
                                            total + outcome.duration_minuites, 0
                                        )} minutes
                                </Typography>
                            </Box>
                            <Box style={{display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
                                <Typography variant="body1" style={{marginTop: '10px', color: '#ef6528'}}>
                                    <strong>Target Audience: </strong>
                                </Typography>
                                <Typography variant="body1" style={{marginTop: '10px',marginLeft:'10px', color: '#000'}}>
                                    {selectedLesson.target_audience}
                                </Typography>
                            </Box>
                        </div>
                        <Box style={{backgroundColor: '#F3F1EC', padding: '20px', width:'100%'}}>
                            <Typography variant="body1" style={{marginBottom: '15px', color: '#ef6528'}}>
                                <strong>Student Outcomes:</strong>
                            </Typography>
                            {selectedLesson.student_outcomes.map((outcome, outcomeIndex) => (
                                <Box
                                    key={outcomeIndex}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="start"
                                    style={{marginBottom: '10px', backgroundColor: '#F3F1EC',}}
                                >
                                    <Typography variant="body2" style={{
                                        border: '2px solid #ef6528',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        marginRight: '10px',
                                        width: '10%'
                                    }}>
                                        {outcome.duration_minuites} minutes
                                    </Typography>
                                    <Typography style={{width: '76%'}}>
                                        {outcome.student_outcomes}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleRemoveOutcome(outcomeIndex)}
                                        style={{borderColor: '#ef6528', color: '#ef6528', marginLeft: '10px'}}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                        <Button
                            variant="outlined"
                            className={classes.Button}
                            style={{padding: '7px 37px', marginTop: '60px', width:'150px', marginBottom:'25px'}}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Box>
                )}
                {selectedLesson && selectedSection === 'Introduction' && (
                    <Introduction
                        key={selectedLesson.verbose_name}
                        selectedLesson={selectedLesson}
                        selectedSkills={selectedSkills}
                    />
                )}
                {selectedLesson && selectedSection === 'Learning Content' && (
                    <LearningContent
                        key={selectedLesson.verbose_name}
                        selectedLesson={selectedLesson}
                        selectedSkills={selectedSkills}
                    />
                )}
                {selectedLesson && selectedAssignment && selectedSection === 'Pre Assessment' &&(
                    <ProgramPreAssessment
                        key={selectedAssignment.verbose_name}
                        selectedSkills={selectedSkills}
                        selectedLesson={selectedLesson}
                        assignmentData={assignmentData}
                        setAssignmentData={setAssignmentData}
                    />
                )}
                {selectedLesson && selectedSection === 'Application Activity' && (
                    <ApplicationActivity
                        key={selectedLesson.verbose_name}
                        selectedLesson={selectedLesson}
                        selectedSkills={selectedSkills}
                    />
                )}
                {selectedLesson && selectedAssignment && selectedSection === 'Post Assessment' &&(
                    <ProgramPostAssessment
                        key={selectedAssignment.verbose_name}
                        selectedSkills={selectedSkills}
                        selectedLesson={selectedLesson}
                        assignmentData={assignmentData}
                        setAssignmentData={setAssignmentData}
                    />
                )}
            </Box>
            </div>
        </Container>
    )
}
export default SuggestedCourse;