import React, { useState } from 'react';
import ProgramCreationView from '../../views/ver_24.10/ProgramCreation.view'
import {CHAT_TYPE} from "../../utils/constants";
import {useHistory} from "react-router-dom";
import Loader from "../../ui_library/ver_24.10/Loader/Loader.comp";

const ProgramCreationPage = () => {
    const history = useHistory();
    const [chatHistory, setChatHistory] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const data = {
        programTitle: 'Digital Literacy Program',
        chats: chatHistory
    };

    const options = {
        height: '570px', //small, medium, large sm/md/lg
        chatType: 'programCreation',
    };

    const actions = {
        sendMessageAction: (message) => {
            setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                { type: CHAT_TYPE.USER, text: message },
            ]);

            return new Promise((resolve) => {
                setTimeout(() => {
                    let responseData;
                    setChatHistory((prevChatHistory) => {
                        const updatedChatHistory = [...prevChatHistory];
                        const chatLength =updatedChatHistory.filter(
                            (msg) => msg.type === CHAT_TYPE.USER
                        ).length;

                        switch (chatLength) {
                            case 1:
                                responseData = {
                                    response_message: 'Welcome! How can I assist you today?',
                                };
                                break;
                            case 2:
                                responseData = {
                                    response_message:
                                        'Sure, I can help with that. Could you provide more details?',
                                };
                                break;
                            case 3:
                                responseData = {
                                    response_message: 'Your program has been created!',
                                    programId: 'programId123',
                                };
                                break;
                            case 4:
                            default:
                                responseData = {
                                    response_message: 'Thank you for using our service!',
                                };
                                break;
                        }

                        updatedChatHistory.push({
                            type: CHAT_TYPE.BOT,
                            text: responseData.response_message,
                        });

                        return updatedChatHistory;
                    });

                    resolve({ data: responseData });
                    if (responseData.programId) {
                        setShowLoader(true);
                        setTimeout(() => {
                            history.push(`/program/${responseData.programId}/dashboard?step=1`);
                        }, 2000);
                    }
                }, 1000);
            });
        },
    };
    if (showLoader) {
        return <Loader />;
    }
    return (
        <div>
            <ProgramCreationView
                data={data}
                actions={actions}
                options={options}
            />
        </div>
    );
};

export default ProgramCreationPage;
