import React, {useState} from 'react'
import ProgramPreviewView from '../../views/ver_24.10/ProgramPreview.view'
import {
    Layout1, RightImageLayout,
    LeftImageLayout, Layout3,
    Layout6, Layout7, Layout8,Layout10
} from "../../ui_library/ver_24.10/SlidesLayouts/SlideLayouts.comp";
import Slide2Image from '../../assets/images/SlidesImages/img.png'
import Slide4Image from '../../assets/images/SlidesImages/img_1.png'
import Slide6Image from '../../assets/images/SlidesImages/img_2.png'
import Slide11Image from '../../assets/images/SlidesImages/img_3.png'
import {CHAT_TYPE} from "../../utils/constants";
import {useHistory} from "react-router-dom";
import Loader from "../../ui_library/ver_24.10/Loader/Loader.comp";
const ProgramPreview = () => {
    const history = useHistory();
    const [showLoader, setShowLoader] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [curriculum, setCurriculum] = useState([
        {
            unitId: 'unit1',
            title: 'Unit 1: Aware Digital Citizens',
            lessons: [
                { lessonId: 'lesson1.1', title: 'Lesson 1: Online Personas (10 mins)' },
                { lessonId: 'lesson1.2', title: 'Lesson 2: Digital Presence (10 mins)' },
                { lessonId: 'lesson1.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)' },
                { lessonId: 'lesson1.4', title: 'Lesson 4: Global Digital Citizenship (7 mins)' },
            ],
        },
        {
            unitId: 'unit2',
            title: 'Unit 2: Engaging Digital Learners',
            lessons: [
                { lessonId: 'lesson2.1', title: 'Lesson 1: Online Personas (5 mins)' },
                { lessonId: 'lesson2.2', title: 'Lesson 2: Digital Presence (10 mins)' },
                { lessonId: 'lesson2.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)' },
            ],
        },
    ]);
    const [slideData, setSlideData] = useState([
        {
            component: Layout1,
            props: { videoSrc: "video-url.mp4" },
        },
        {
            component: LeftImageLayout,
            props: {
                imageSrc: Slide2Image,
                title: "Types of Digital Identity",
                paragraph: "Individuals can have multiple digital identities, crafted for different audiences, such as one for family and another for friends."
            },
            options: { backgroundColor: '#F9A83E',height: '335px'}
        },
        {
            component: Layout3,
            props: {
                title: "Explore Digital Skills Modules",
                cardContent: [
                    'Your profile - the choice of avatar',
                    'Pictures you share online',
                    'Comments you make online',
                    'How old or new the model of your mobile phone is',
                ]
            },
            options: { backgroundColor: '#F9A83E',}
        },
        {
            component: RightImageLayout,
            props: {
                title: "The Impact of Online Representation",
                paragraph: "How you present yourself online can influence people's perceptions of you.",
                imageSrc: Slide4Image
            },
            options: { backgroundColor: '#4285F4', showButton: false}
        },
        {
            component: LeftImageLayout,
            props: {
                title: "The Impact of Online Representation",
                paragraph: "Your online actions contribute to your reputation.",
                imageSrc: Slide4Image
            },
            options: { backgroundColor: '#F9A83E',height: '275px'}
        },
        {
            component: Layout6,
            props: {
                title: "Your Digital Journey",
                cardContent: [
                    { title: 'Think Before You Post', text: `Always ask yourself if what you're sharing is kind, true and safe. Once something is posted online, it can be hard to take back!` },
                    { title: 'Keep Personal Info Private', text: 'Be careful not to share things like your home address, school, or phone number. Use privacy settings to control who can see your posts.' },
                    { title: 'Be Yourself, But Stay Safe', text: `It's fun to show your interests and personality online, but remember to only interact with people you know and trust. Don't pretend to be someone you're not.` }
                ]
            },
            options: { backgroundColor: '#F9A83E',}
        },
        {
            component: Layout7,
            props: {
                title: "Summary Recap",
                listItems: [
                    {
                        title: "Understanding Digital Identities",
                        description: "Your digital identity is made up of social media profiles, usernames, and shared content. It can vary across different platforms and audiences."
                    },
                    {
                        title: "Fluidity and Identity Play",
                        description: "We often experiment with different personas online, adapting to different contexts, while staying true to core values. Balance authenticity with exploration."
                    },
                    {
                        title: "Fluidity and Identity Play",
                        description: "We often experiment with different personas online, adapting to different contexts, while staying true to core values. Balance authenticity with exploration."
                    },
                    {
                        title: "Fluidity and Identity Play",
                        description: "We often experiment with different personas online, adapting to different contexts, while staying true to core values. Balance authenticity with exploration."
                    }
                ]
            },
            options: { backgroundColor: '#FFAC40',}
        },
        {
            component: Layout8,
            props: { text: "Let's Test What You Have Learned" },
            options: { backgroundColor: '#FEAB40',}
        },
        {
            component: RightImageLayout,
            props: {
                paragraph: "Take a look at the online profile on the right, click on everything you see that would be considered part of your digital identity. When finished, click ‘Done’ below.",
                imageSrc: Slide6Image
            },
            options: { backgroundColor: '#4285F4', showButton: true}
        },
        {
            component: RightImageLayout,
            props: {
                title: "Your Score",
                paragraph: "Your DQ Score is 106, which is above average. Great job! Keep up the good work and continue exploring more ways to improve your digital presence and safety online.",
                imageSrc: Slide11Image
            },
            options: { backgroundColor: '#4285F4', showButton: false}
        },
        {
            component: Layout10,
            props: { text: "Let's Test What You Have Learned" },
            options: { backgroundColor: '#FEAB40',}
        },
    ]);

    const data = {
        curriculum,
        slideData,
        chats: chatHistory
    };

    const options={
        height: '210px',
        bottom:'6%',
        chatType: 'programPreviewSlides',
    };

    const actions={
        sendMessageAction: (message) => {
            setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                { type: CHAT_TYPE.USER, text: message },
            ]);

            return new Promise((resolve) => {
                setTimeout(() => {
                    let responseData;

                    setChatHistory((prevChatHistory) => {
                        const updatedChatHistory = [...prevChatHistory];

                        const chatLength = updatedChatHistory.filter(
                            (msg) => msg.type === CHAT_TYPE.USER
                        ).length;

                        switch (chatLength) {
                            case 1:
                                responseData = {
                                    response_message: 'Do you know its consequences?',
                                };
                                break;
                            case 2:
                                const lastUserMessage = message.toLowerCase().trim();
                                if (lastUserMessage === 'yes') {
                                    responseData = {
                                        response_message:
                                            'Great! Can you tell me more about what you know?',
                                    };
                                } else if (lastUserMessage === 'no') {
                                    responseData = {
                                        response_message:
                                         'No worries, I can guide you through the consequences.',
                                    };
                                } else {
                                    responseData = {
                                        response_message: 'Please answer with yes or no.',
                                    };
                                }
                                break;
                            case 3:
                                responseData = {
                                    response_message: 'Your program outline has been updated!',
                                    programId: 'programId123',
                                };
                                break;
                            case 4:
                            default:
                                responseData = {
                                    response_message: 'Thank you for using our service!',
                                };
                                break;
                        }

                        updatedChatHistory.push({
                            type: CHAT_TYPE.BOT,
                            text: responseData.response_message,
                        });


                        resolve({ data: responseData });
                        if (responseData.programId) {
                            setShowLoader(true);
                            setTimeout(() => {
                                history.push(`/program/${responseData.programId}/dashboard?step=2`);
                            }, 2000);
                        }
                        return updatedChatHistory;
                    });
                }, 1000);
            });
        },
    }

    if (showLoader) {
        return <Loader />;
    }
    return (
        <>
            <ProgramPreviewView data={data} options={options} actions={actions}/>
        </>
    );
}

export default ProgramPreview;