import React, { useState } from 'react';
import ProgramOutlineView from "../../views/ver_24.10/ProgramOutline/ProgramOutline.view";
import {useHistory} from "react-router-dom";
import {CHAT_TYPE} from "../../utils/constants";
import Loader from "../../ui_library/ver_24.10/Loader/Loader.comp";

const ProgramOutlinePage = () => {
    const history = useHistory();
    const [chatHistory, setChatHistory] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [unitsData, setUnitsData] = useState([
        {
            unitId: 'unit1',
            title: 'Unit 1: Aware Digital Citizens (35 mins total)',
            lessons: [
                { lessonId: 'lesson1.1', title: 'Lesson 1: Online Personas (10 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
                { lessonId: 'lesson1.2', title: 'Lesson 2: Digital Presence (10 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
                { lessonId: 'lesson1.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
                { lessonId: 'lesson1.4', title: 'Lesson 4: Global Digital Citizenship (7 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
            ],
        },
        {
            unitId: 'unit2',
            title: 'Unit 2: Engaging Digital Learners (23 mins total)',
            lessons: [
                { lessonId: 'lesson2.1', title: 'Lesson 1: Online Personas (5 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
                { lessonId: 'lesson2.2', title: 'Lesson 2: Digital Presence (10 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
                { lessonId: 'lesson2.3', title: 'Lesson 3: Global Digital Citizenship (8 mins)', studentOutcomes: ['Students demonstrate a clear understanding of how their self-image (or persona) is constructed and represented in digital environments.', 'Students effectively create and manage multiple online identities or personas, tailored to different contexts such as personal and professional lives.'] },
            ],
        },
    ]);

    const data = {
        unitsData,
        chats: chatHistory
    };

    const options = {
        height: '210px',
        chatType: 'programOutline',
        chatPreview: false,
    };

    const actions = {
        removeUnitAction: (unitId) => {
            setUnitsData((prevUnits) => prevUnits.filter((unit) => unit.unitId !== unitId));
        },

        sendMessageAction: (message) => {
            setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                { type: CHAT_TYPE.USER, text: message },
            ]);

            return new Promise((resolve) => {
                setTimeout(() => {
                    let responseData;

                    setChatHistory((prevChatHistory) => {
                        const updatedChatHistory = [...prevChatHistory];

                        const chatLength = updatedChatHistory.filter(
                            (msg) => msg.type === CHAT_TYPE.USER
                        ).length;

                        switch (chatLength) {
                            case 1:
                                responseData = {
                                    response_message:
                                        'Sure, I can help you with your program outline.',
                                    programId: 'programId123',}
                                break;
                            case 2:
                                responseData = {
                                    response_message: 'What changes would you like to make?',}
                                break;
                            case 3:
                                responseData = {
                                    response_message:
                                        'Your program outline has been updated!',
                                    programId: 'programId123',
                                };
                                break;
                            case 4:
                            default:
                            responseData = {
                                response_message: 'Thank you for using our service!',
                            };
                                break;
                        }
                        updatedChatHistory.push({
                            type: CHAT_TYPE.BOT,
                            text: responseData.response_message,
                        });

                        resolve({ data: responseData });
                        if (responseData.programId) {
                            setShowLoader(true);
                            setTimeout(() => {
                                history.push(`/program/${responseData.programId}/dashboard?step=2`);
                            }, 2000);
                        }
                        return updatedChatHistory;
                    });
                }, 1000);
            });
        },
    };
    if (showLoader) {
        return <Loader />;
    }

    return (
        <div>
            <ProgramOutlineView
                data={data}
                actions={actions}
                options={options}
            />
        </div>
    );
};

export default ProgramOutlinePage;
