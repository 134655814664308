import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    title: {
        color: '#002060',
        fontWeight: 'bold',
        marginTop: '50px !important',
        marginBottom:'30px !important',
        fontSize: '35px',
        textAlign:'center',
    },
    subtitle: {
        color: '#EE6628',
        fontSize: '26px',
    },
    card:{
        minWidth:'235px',
        position:'relative',
        backgroundColor:'#fff',
        margin:'20px',
        width:'450px',
        height:'500px',
        display:'flex',
        flexDirection:'column',
        borderRadius:'15px !important',
        alignItems:'center',
    },
    button: {
        border: '2px solid #EE6628 !important',
        margin:'10px !important',
        backgroundColor:'#fff !important',
        width:'100px !important',
        padding:'7x !important',
        color: '#EE6628 !important',
        '&:hover': {
            backgroundColor: '#EE6628 !important',
            color: '#fff !important',
        },
    },
    upgradeButton: {
        position: 'absolute !important',
        top: '0px !important',
        right: '0px !important',
        backgroundColor: '#002060 !important',
        color: '#FFFFFF !important',
        zIndex: 2,
        '&:hover': {
            backgroundColor: '#00153b !important',
        },
    },
    disabledCard: {
        backgroundColor: '#D8D8D8 !important',
        color: '#606060 !important',
        '& h5, & p': {
            color: '#606060 !important',
        },
        '& $button': {
            backgroundColor: '#606060 !important',
            color: '#fff !important',
            border: 'none !important',
            '&:hover': {
                backgroundColor: '#505050 !important',
            },
        },
    },
    enabledCard: {
        backgroundColor: '#fff',
        color: '#000',
    },
    activeButton: {
        backgroundColor: '#EE6628 !important',
        color: '#fff !important',
    },
}));

export default useStyles;