import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import { ChromePicker } from 'react-color';
import InvertColors from '@material-ui/icons/InvertColors';
import RazzChar from "../../../assets/images/Razz_Img.png";
import SmileImg from "../../../assets/images/smile-icon.png";
import ContactImg from "../../../assets/images/contacts-icon.png";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LayoutA, LayoutB, LayoutC } from './Layouts.comp';
import { THEME_LAYOUT } from '../../../pages/Packages/CustomDigitalProgram/theme_store';


const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: '1200px',
        height: '690px',
        borderRadius: '30px',
        margin: '0 auto',
        overflow: 'hidden',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 60px',
        borderRadius: '4px',
        textAlign: 'center',
        backgroundColor: '#ed6628',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        width: '530px',
    },
    titleImage: {
        maxWidth: '180px',
        maxHeight: '150px',
        objectFit: 'cover',
        marginTop: '-10px',
    },
    stepsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginTop: '50px',
        width: '100%',
        gap: theme.spacing(2),
    },
    stepItem: {
        flex: 1,
        padding: theme.spacing(1),
    },
    stepTitle: {
        fontWeight: 'bold',
        fontSize: '22px',
    },
    toolbarOverlay: {
        position: 'fixed',
        top: '40%',
        right: '9.6%',
        transform: 'translateY(-50%)',
        minWidth: '50px',
        width: 'auto',
        borderRadius:'100px',
        marginTop:'85px',
        height: '77%',
        backgroundColor: '#f5f5f5',
        zIndex: 10001,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
        transition: 'width 0.3s ease',
    },
    toolbarContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center',
    },
    closeButton: {
        alignSelf: 'flex-end',
    },
    colorPickerWrapper: {
        position: 'absolute',
        zIndex: 10002,
        top: '50%',
        left: '15px',
        transform: 'translateX(-100%)',
    },
    textColorPickerWrapper: {
        position: 'absolute',
        zIndex: 10002,
        top: '87%',
        left: '11px',
        transform: 'translateX(-100%)',
    },
    openToolbarButton: {
        position: 'absolute',
        top: '50%',
        right: '6px',
        transform: 'translateY(-50%)',
        zIndex: 10001,
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    fontStyleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        position: 'absolute',
        top: '60px',
        right: '0px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(2),
        zIndex: 10002,
        width: '200px',
        borderRadius: '8px',
    },
    formControl: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
    },
    selectInput: {
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        maxWidth: '100%',
    },
    menuItemText: {
        whiteSpace: 'nowrap',
    },
    imageOptionsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        position: 'absolute',
        top: '60px',
        right: '0px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(1),
        zIndex: 10002,
        width: '200px',
        borderRadius: '8px',
    },
    imageOption: {
        width: '40px',
        height: '40px',
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: '8px',
        margin: theme.spacing(0.5),
    },
    slideLabels: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '28px',
    },

    slideLabel: {
        padding: '5px 15px',
        cursor: 'pointer',
        color:'#fff',
        margin:'0 15px',
        fontSize: '16px',
        fontFamily:'Arial',
    },

    activeSlide: {
        fontWeight: 'bold',
        backgroundColor:'#4294A5',
        borderRadius:'20px',
    },
    navigationButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        top: '6%',
        left: '0',
        right: '0',
        transform: 'translateY(-50%)',
        padding: '0 20px',
        boxSizing: 'border-box',
},

    leftButton: {
        color:'#fff',
        position: 'absolute',
        left: '20px',
    },
    rightButton: {
        color:'#fff',
        position: 'absolute',
        right: '20px',
    }

}));

export const themes = [
    {
        backgroundColor: '#a6a6a6',
        color: '#000000',
        fontSize: '20px',
        fontFamily: 'Arial',
        layout: THEME_LAYOUT.LAYOUT_A,
    },
    {
        backgroundColor: '#059ac9',
        color: '#070707',
        fontSize: '20px',
        fontFamily: 'Georgia',
        layout: THEME_LAYOUT.LAYOUT_B,
    },
    {
        backgroundColor: '#c41919',
        color: '#030303',
        fontSize: '20px',
        fontFamily: 'Verdana',
        layout: THEME_LAYOUT.LAYOUT_C,
    }
];

const PopupEditor = ({ open, onClose, content }) => {
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0);
    // const slides = [
    //     { title: 'Slide 1', content: content.content },
    //     { title: 'Slide 2', content: content.content },
    //     { title: 'Slide 3', content: content.content },
    //     { title: 'Slide 4', content: content.content },
    //     { title: 'Slide 5', content: content.content },
    //     { title: 'Slide 6', content: content.content },
    // ];
    const slides = Array.isArray(content?.body) ? content.body.map((section) => ({
        title: section.title || '',
        content: section.content || '',
    })) : [];

    const [backgroundColor, setBackgroundColor] = useState('#ed6628');
    const [textColor, setTextColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Arial');
    const FONT_SIZES = {
        SMALL: 17,
        MEDIUM: 25,
        LARGE: 38,
    };
    const [fontSize, setFontSize] = useState(FONT_SIZES.MEDIUM);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showTextMenu, setShowTextMenu] = useState(false);
    const [showToolbar, setShowToolbar] = useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = useState(false);
    const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
    const imageOptions = [
        { src: SmileImg, alt: 'Smile Image' },
        { src: RazzChar, alt: 'Razz Image' },
        { src: ContactImg, alt: 'Contact Image' },
    ];
    const [selectedImage, setSelectedImage] = useState(RazzChar);
    const [showImageOptions, setShowImageOptions] = useState(false);
    const colorPickerRef = useRef(null);
    const textColorPickerRef = useRef(null);
    const imageOptionsRef = useRef(null);
    const fontFamilyRef = useRef(null);
    const [selectedTextType, setSelectedTextType] = React.useState('body');
    const handleTextTypeChange = (event) => {
        setSelectedTextType(event.target.value);
    };
    const handleImageOptionsToggle = () => {
        setShowImageOptions(prev => !prev);
    };

    const handleImageSelect = (imageSrc) => {
        setSelectedImage(imageSrc);
        // setShowImageOptions(false);
    };
    const handleThemeChange = () => {
        const nextThemeIndex = (currentThemeIndex + 1) % themes.length;
        const nextTheme = themes[nextThemeIndex];

        const updatedSlideStyles = {
            ...slideStyles,
            [currentSlide]: {
                ...slideStyles[currentSlide],
                backgroundColor: nextTheme.backgroundColor,
                color: nextTheme.color,
                fontSize: nextTheme.fontSize,
                fontFamily: nextTheme.fontFamily,
                layout: nextTheme.layout,
            }
        };

        setSlideStyles(updatedSlideStyles);
        setCurrentThemeIndex(nextThemeIndex);
    };

    const handleBackgroundColorChange = (color) => {
        handleStyleChange('backgroundColor', color.hex);
    };
    const handleFontSizeChange = (event, textType) => {
        console.log("Font Size Change:", event.target.value, textType);
        setSlideStyles((prevStyles) => ({
            ...prevStyles,
            [currentSlide]: {
                ...prevStyles[currentSlide],
                fontSize: {
                    ...prevStyles[currentSlide].fontSize,
                    [textType]: FONT_SIZES[event.target.value],
                },
            },
        }));
    };

    const handleFontFamilyChange = (event, textType) => {
        setSlideStyles((prevStyles) => ({
            ...prevStyles,
            [currentSlide]: {
                ...prevStyles[currentSlide],
                fontFamily: {
                    ...prevStyles[currentSlide]?.fontFamily,
                    [textType]: event.target.value,
                },
            },
        }));
    };

    const handleTextColorChange = (color, textType) => {
        setSlideStyles((prevStyles) => ({
            ...prevStyles,
            [currentSlide]: {
                ...prevStyles[currentSlide],
                color: {
                    ...prevStyles[currentSlide]?.color,
                    [textType]: color.hex,
                },
            },
        }));
    };

    const toggleColorPicker = () => {
        setShowColorPicker(prev => !prev);
        if (showTextColorPicker) {
            setShowTextColorPicker(false);
        }
    };
    const defaultSlideStyle = {
        fontSize: { h1: FONT_SIZES.LARGE, h2:FONT_SIZES.MEDIUM, body: FONT_SIZES.SMALL },
        fontFamily: { h1: 'Arial',h2: 'Arial', body: 'Verdana' },
        color: { h1: '#000',h2: '#000000', body: '#fff' },
        backgroundColor: '#ed6628',
        layout: THEME_LAYOUT.LAYOUT_A,
    };

    const [slideStyles, setSlideStyles] = useState(
        Array(slides.length).fill(defaultSlideStyle)
    );
    const handleStyleChange = (property, value) => {
        setSlideStyles(prevStyles => ({
            ...prevStyles,
            [currentSlide]: {
                ...prevStyles[currentSlide],
                [property]: value,
            }
        }));
    };

    const toggleTextMenu = () => {
        setShowTextMenu(prev => !prev);
    };
    const handleClickOutside = (event) => {
        const isClickInsideColorPicker = colorPickerRef.current && colorPickerRef.current.contains(event.target);
        const isClickInsideImageOptions = imageOptionsRef.current && imageOptionsRef.current.contains(event.target);
        const isClickInsideTextColorPicker = textColorPickerRef.current && textColorPickerRef.current.contains(event.target);
        const isClickInsideFontFamilyOptions = fontFamilyRef.current && fontFamilyRef.current.contains(event.target);
        if (
            !(isClickInsideColorPicker ||
                isClickInsideImageOptions ||
                isClickInsideTextColorPicker ||
                isClickInsideFontFamilyOptions)
        ) {
            setShowColorPicker(false);
            setShowImageOptions(false);
            setShowTextColorPicker(false);
        }
    };

    const handleNextSlide = () => {
        if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePreviousSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const renderSlideLabels = () => (
        <div className={classes.slideLabels} style={{ backgroundColor }}>
            {slides.map((slide, index) => (
                <span
                    key={index}
                    className={`${classes.slideLabel} ${index === currentSlide ? classes.activeSlide : ''}`}
                    onClick={() => setCurrentSlide(index)}
                >
                    {`Slide ${index + 1}`}
                </span>
            ))}
        </div>
    );
    useEffect(() => {
        setBackgroundColor(slideStyles[currentSlide]?.backgroundColor || '#ed6628');
        setTextColor(slideStyles[currentSlide]?.color || '#000');
        setFontFamily(slideStyles[currentSlide]?.fontFamily || 'Arial');
        setFontSize(slideStyles[currentSlide]?.fontSize || FONT_SIZES.MEDIUM);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [currentSlide, slideStyles]);



    const steps = slides[currentSlide]?.content.split('\n').filter(step => step.trim() !== '').map((step, index) => {
        const parts = step.split(':');
        const style = { fontFamily, fontSize: `${fontSize}px` };
        if (parts.length > 1) {
            return (
                <div className={classes.stepItem} key={index} style={style}>
                    <div className={classes.stepTitle}>
                        <h2 style={{
                            fontSize: slideStyles[currentSlide]?.fontSize?.h2 || 'inherit',
                            fontFamily: slideStyles[currentSlide]?.fontFamily?.h2 || 'inherit',
                            color: slideStyles[currentSlide]?.color?.h2 || 'inherit'
                        }}>{parts[0]}</h2>
                    </div>
                    <div>{parts[1]}</div>
                </div>
            );
        }
        return <div key={index} style={style}>{step}</div>;
    });
    console.log('PopupEditor Props:', {content, slideStyles, currentSlide });
    return (
        <>
            <Dialog open={open} onClose={onClose} classes={{paper: classes.dialogPaper}}>
                {renderSlideLabels()}
                <DialogContent
                    className={classes.content}
                    style={slideStyles[currentSlide]}
                >
                    {(() => {
                        const layout = slideStyles[currentSlide]?.layout;

                        switch (layout) {
                            case THEME_LAYOUT.LAYOUT_A:
                                return (
                                    <LayoutA
                                        imageSrc={selectedImage}
                                        title={content.title}
                                        content={steps}
                                        slideStyles={slideStyles}
                                        currentSlide={currentSlide}
                                    />
                                );
                            case THEME_LAYOUT.LAYOUT_B:
                                return (
                                    <LayoutB
                                        imageSrc={selectedImage}
                                        title={content.title}
                                        content={steps}
                                        slideStyles={slideStyles}
                                        currentSlide={currentSlide}
                                    />
                                );
                            case THEME_LAYOUT.LAYOUT_C:
                                return (
                                    <LayoutC
                                        imageSrc={selectedImage}
                                        title={content.title}
                                        content={steps}
                                        slideStyles={slideStyles}
                                        currentSlide={currentSlide}
                                    />
                                );
                            default:
                                return (
                                    <LayoutA
                                        imageSrc={selectedImage}
                                        title={content.title}
                                        content={steps}
                                        slideStyles={slideStyles}
                                        currentSlide={currentSlide}
                                    />
                                );
                        }
                    })()}
                    <div className={classes.navigationButtons}>
                        <IconButton className={classes.leftButton} onClick={handlePreviousSlide}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <IconButton className={classes.rightButton} onClick={handleNextSlide}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </div>
                </DialogContent>
                <div className={classes.openToolbarButton}>
                    <IconButton onClick={() => setShowToolbar(!showToolbar)}>
                        <ColorLensIcon />
                    </IconButton>
                </div>

                {showToolbar && (
                    <div className={classes.toolbarOverlay}>
                        <IconButton
                            className={classes.closeButton}
                            onClick={() => {
                                setShowToolbar(false);
                                // onClose();
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <div className={classes.toolbarContent}>
                            {/* Background Color Picker */}
                            <div style={{ position: 'relative' }}>
                                <IconButton onClick={toggleColorPicker}>
                                    <ColorLensIcon/>
                                </IconButton>
                                {showColorPicker && (
                                    <div ref={colorPickerRef} className={classes.colorPickerWrapper}>
                                        <ChromePicker
                                            color={slideStyles[currentSlide]?.backgroundColor}
                                            onChange={handleBackgroundColorChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ position: 'relative' }}>
                                <IconButton onClick={toggleTextMenu}>
                                    <TextFieldsIcon/>
                                </IconButton>
                                {showTextMenu && (
                                    <div className={classes.fontStyleWrapper}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="text-type-select-label">Text Type</InputLabel>
                                            <Select
                                                labelId="text-type-select-label"
                                                value={selectedTextType}
                                                onChange={handleTextTypeChange}
                                            >
                                                <MenuItem value="h1">Heading 1</MenuItem>
                                                <MenuItem value="h2">Heading 2</MenuItem>
                                                <MenuItem value="body">Body Text</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="font-size-select-label">Font Size</InputLabel>
                                            <Select
                                                labelId="font-size-select-label"
                                                value={Object.keys(FONT_SIZES).find(
                                                    (size) => FONT_SIZES[size] === slideStyles[currentSlide]?.fontSize[selectedTextType]
                                                )}
                                                onChange={(e) => handleFontSizeChange(e, selectedTextType)}
                                            >
                                                {Object.keys(FONT_SIZES).map((size) => (
                                                    <MenuItem key={size} value={size}>
                                                        {size}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <div ref={fontFamilyRef}>
                                            <FormControl style={{ minWidth: '165px' }}>
                                                <InputLabel>Font Family</InputLabel>
                                                <Select
                                                    value={slideStyles[currentSlide]?.fontFamily[selectedTextType] || 'Arial'}
                                                    onChange={(e) => handleFontFamilyChange(e, selectedTextType)}
                                                >
                                                    <MenuItem value="Arial">Arial</MenuItem>
                                                    <MenuItem value="Courier New">Courier New</MenuItem>
                                                    <MenuItem value="Georgia">Georgia</MenuItem>
                                                    <MenuItem value="Times New Roman">Times New Roman</MenuItem>
                                                    <MenuItem value="Verdana">Verdana</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <IconButton onClick={() => setShowTextColorPicker(!showTextColorPicker)}>
                                                <ColorLensIcon/>
                                            </IconButton>
                                            {showTextColorPicker && (
                                                <div ref={textColorPickerRef} className={classes.textColorPickerWrapper}>
                                                    <ChromePicker
                                                        color={slideStyles[currentSlide]?.color[selectedTextType] || '#000000'}
                                                        onChange={(color) => handleTextColorChange(color, selectedTextType)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{position: 'relative'}}>
                                <IconButton onClick={handleThemeChange}>
                                    <InvertColors/>
                                </IconButton>
                            </div>

                            <div style={{position: 'relative'}}>
                                <IconButton onClick={handleImageOptionsToggle}>
                                    <ImageIcon/>
                                </IconButton>
                                {showImageOptions && (
                                    <div ref={imageOptionsRef} className={classes.imageOptionsWrapper}>
                                        {imageOptions.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                alt={image.alt}
                                                className={classes.imageOption}
                                                onClick={() => handleImageSelect(image.src)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default PopupEditor;