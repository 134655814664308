import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        minHeight: '100vh',
    },
    topImage: {
        height: '150px',
        marginBottom: '20px',
        objectFit: 'cover',
    },
    cardsContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '20px',
    },
    card: {
        width: '25%',
        margin:'20px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        textAlign: 'center',
        color: '#606060',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
        },
    },
    cardImage: {
        height: '100px',
        objectFit: 'cover',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    cardContent: {
        padding: '20px',
    },
    listItemText: {
        color: '#606060',
        textAlign:'left',
        fontSize:'20px',
        paddingRight:'10px',
    },
}));

export default useStyles;