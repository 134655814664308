import React from 'react'

const RatingContentReport = () => {
    return (
        <>
            <div>
                <h1>Rating Content Report</h1>
                <p>Rating Content Report page for a specific program.</p>
            </div>
        </>
    );
}

export default RatingContentReport;