import React from 'react'
import HomeView from "../../views/ver_24.10/Home.view";

const HomePage= () =>{
    return(
        <>
            <div>
                <HomeView/>
            </div>
        </>
    );
}

export default HomePage;